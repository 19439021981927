import React from 'react';
import styles from './TextPrimary.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const TextPrimary = ({ text, strong, className }) => {
    return (
        <p className={cx(styles.container, className, { [styles.strong]: strong })}>
            {text}
        </p>
    )
}
TextPrimary.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    strong: PropTypes.bool,
    className: PropTypes.string
}
TextPrimary.defaultProps = {
    strong: false,
    className: ''
}