import React from 'react';
import styles from './InputField.module.css';
import { Label } from 'shopify/components/Label/Label';
import { Input } from 'shopify/components/Input/Input';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { TextWarning } from 'shopify/components/TextWarning/TextWarning';

const cx = classNames.bind(styles);
export const InputField = ({ id, name, type, label, required, onChange, placeholder, value, warning, className, labelClassName, error, autoFocus, onFocus }) => {
    return (
        <div className={cx(styles.container, className)}>
            <Label
                htmlFor={id}
                text={label}
                required={required}
                className={labelClassName}
            />
            <Input
                id={id}
                name={name || id}
                type={type}
                onChange={onChange}
                onFocus={onFocus}
                autoFocus={autoFocus}
                placeholder={placeholder}
                required={required}
                value={value}
                warning={warning}
            />
            <TextWarning
                text={error}
            />
        </div>
    )
}
InputField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    warning: PropTypes.bool.isRequired,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    error: PropTypes.string,
    autoFocus: PropTypes.bool,
    onFocus: PropTypes.func
}
InputField.defaultProps = {
    className: '',
    labelClassName: '',
    error: '',
    autoFocus: false,
    onFocus: () => {}
}