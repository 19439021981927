import React from 'react';
import styles from './StringStrengthLine.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
export const StringStrengthLine = ({ strength }) => {
    return (
        <div className={styles.container}>
            <div className={cx(styles.fill, styles[strength])}/>
        </div>
    )
}
StringStrengthLine.propTypes = {
    strength: PropTypes.string
}
StringStrengthLine.defaultProps = {
    strength: 'default'
}