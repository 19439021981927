import React from 'react';
import styles from 'shopify/modules/SliderCard/SliderCard.module.css';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import PropTypes from 'prop-types';

export const SliderCard = ({ image, text }) => {
    return (
        <div className={styles.container}>
            {image}
            <TextPrimary
                text={text}
            />
        </div>
    )
};

SliderCard.propTypes = {
    image: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired
}