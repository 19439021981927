import React, { useState } from 'react';
import styles from './InputHidden.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ShowIcon } from 'shopify/icons/ShowIcon';
import { HideIcon } from 'shopify/icons/HideIcon';

const cx = classNames.bind(styles);

export const InputHidden = ({ name, onChange, placeholder, value, required, warning, onFocus, autoFocus }) => {
    const [hidden, setHidden] = useState(true);

    const onClickHideButton = () => {
        setHidden(prev => !prev)
    }

    return (
        <div className={cx(styles.container, { [styles.warning]: warning })}>
            <input
                type={hidden ? 'password' : 'text'}
                name={name}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                value={value}
                required={required}
                className={styles.input}
                autoFocus={autoFocus}
            />
            <button className={styles.button} onClick={onClickHideButton}>
                {hidden
                    ? <ShowIcon/>
                    : <HideIcon/>
                }
            </button>
        </div>
    )
}

InputHidden.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    warning: PropTypes.bool,
    onFocus: PropTypes.func,
    autoFocus: PropTypes.bool
}
InputHidden.defaultProps = {
    placeholder: '',
    value: '',
    required: false,
    warning: false,
    onFocus: () => {},
    autoFocus: false
}