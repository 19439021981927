import React, { forwardRef } from 'react';
import styles from './InputSingleValue.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
export const InputSingleValue = forwardRef(({
    id, type, onChange, onKeyDown, onFocus, onPaste, required, warning, autoFocus, disabled
}, ref) => {
    return (
        <input
            id={id}
            type={type}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onPaste={onPaste}
            autoFocus={autoFocus}
            required={required}
            disabled={disabled}
            maxLength={1}
            ref={ref}
            className={cx(styles.container, { [styles.warning]: warning })}
        />
    );
});

InputSingleValue.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onPaste: PropTypes.func.isRequired,
    required: PropTypes.bool,
    warning: PropTypes.bool,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
};
InputSingleValue.defaultProps = {
    required: false,
    warning: false,
    autoFocus: false,
    disabled: false
};