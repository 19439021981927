import React, { useEffect, useState } from 'react';
import { eq, map, parseInt, slice } from 'lodash';
import PropTypes from 'prop-types';
import styles from 'components/SelectOptions/SelectOptions.module.css';
import classNames from 'classnames/bind';
import { Label } from 'common/_Label/Label';
import { StringUse } from 'lib/StringUse';

const cx = classNames.bind(styles);

const comparisonOperatorsExample = {
    isExist: ['IS EXIST'],
    numeric: ['==', '>=', '<=', '>', '<', '<>'],
    string: ['EQUAL', 'CONTAINS', 'NOT EQUAL', 'NOT CONTAINS'],
    list: ['ALL IN', 'ANY IN', 'NOT ALL IN', 'NOT ANY IN']
};

const checkAttributeComparisonValue = ({ translator, item, onChangeHandler, comparisonOperatorsExample, nonDropdownComparisonOperatorType }) => {
    const {
        comparisonOperator: { name: operatorName, value: operatorValue, required: operatorRequired },
        comparisonValue: { value },
    } = item;
    const prepareOperatorArrayHandler = (value, example) => {
        // For empty field
        if (!value.length) return [...example.isExist];
        // For field array more than one item
        if (value.length > 1) return [...example.list];
        // For field array with only one item and this item is number
        if (parseInt(...value)) return [...example.numeric, ...example.string];
        // For field array with only one item and this item is Jinja field
        if (StringUse.isJinja(...value)) return [...example.numeric, ...example.string, ...example.list];
        // For field array with only one item and this item is string by default
        return example.string;
    }

    // This function exist because, we need to crop list array of comparison operators
    // On Conditional Split -> Client -> attribute or metric modules doesn't need full array of list operators, so we crop them..
    const cropListOptionsArray = (array, type, value) => {
        // On this field we check only exist of type, we don't care what concretely type is, need only exist this value
        // And we check value length. If length > 1 we sure that array of operators is list
        if (type && value.length > 1) {
            // retrun ['ALL IN', 'NOT ALL IN'] by index of array
            return [array[0], array[2]]
        }

        if (eq(type, 'metric') && eq(value.length, 1)) {
            // retrun ['==', '>=', '<=', '>', '<', '<>'] by index of array
            return slice(array, 0, 6)
        }

        return array;
    }

    return {
        disabled: false,
        disabledClass: 'disabled',
        name: 'comparisonOperator',
        id: 'comparison-operator',
        className: 'medium',
        label: translator(`actionSidebars:${operatorName}`),
        onChange: onChangeHandler,
        required: operatorRequired,
        currentValue: operatorValue,
        optionsArray: cropListOptionsArray(prepareOperatorArrayHandler(value, comparisonOperatorsExample), nonDropdownComparisonOperatorType, value),
    }
}
const checkAggregationComparisonValue = ({ translator, item, onChangeHandler, comparisonOperatorsExample }) => {
    const { numeric } = comparisonOperatorsExample;

    const {
        _eventValues: { aggregationComparisonOperator: { name: operatorName, value: operatorValue, required: operatorRequired } },
    } = item;

    return {
        disabled: false,
        disabledClass: 'disabled',
        name: 'aggregationComparisonOperator',
        id: 'aggregationComparisonOperator',
        className: 'medium',
        label: translator(`actionSidebars:${operatorName}`),
        onChange: onChangeHandler,
        required: operatorRequired,
        currentValue: operatorValue,
        optionsArray: numeric,
    }
}

const checkProductAttributeComparisonValue = ({ translator, item, onChangeHandler, comparisonOperatorsExample }) => {
    const { isExist, list, numeric, string } = comparisonOperatorsExample;

    const {
        columnComparisonOperator: { name: operatorName, value: operatorValue, required: operatorRequired },
    } = item;

    return {
        disabled: false,
        disabledClass: 'disabled',
        name: 'columnComparisonOperator',
        id: 'comparison-operator',
        className: 'medium',
        label: translator(`actionSidebars:${operatorName}`),
        onChange: onChangeHandler,
        required: operatorRequired,
        currentValue: operatorValue,
        optionsArray: [...isExist, ...list, ...numeric, ...string],
    }
}

const typeSwitcher = (comparisonOperatorType, props) => {
    const types = {
        attribute: () => checkAttributeComparisonValue(props),
        aggregation: () => checkAggregationComparisonValue(props),
        productAttribute: () => checkProductAttributeComparisonValue(props)
    }

    return types[comparisonOperatorType];
}

export const ComparisonOperatorSelectOptions = ({ translator, item, onChangeHandler, comparisonOperatorType, error, nonDropdownComparisonOperatorType }) => {
    const [config, setConfig] = useState(() => typeSwitcher(comparisonOperatorType, { translator, item, onChangeHandler, comparisonOperatorsExample, nonDropdownComparisonOperatorType } ));

    useEffect(() => {
        setConfig(typeSwitcher(comparisonOperatorType, { translator, item, onChangeHandler, comparisonOperatorsExample, nonDropdownComparisonOperatorType } ));
    }, [translator, item, onChangeHandler, comparisonOperatorType, nonDropdownComparisonOperatorType]);

    return (
        <>
            <Label htmlFor={config.name} text={config.label}/>
            <select
                disabled={false}
                name={config.name}
                id={config.id}
                className={cx(styles.selectContainer, config.className, { [config.disabledClass]: config.disabled, 'error': error })}
                onChange={config.onChange}
                required={config.required}
                value={config.currentValue}
            >
                <option value=''> -- select an operator</option>
                {map(config.optionsArray, option => <option key={option} value={option}>{option}</option>)}
            </select>
        </>
    );
}
ComparisonOperatorSelectOptions.propTypes = {
    translator: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    comparisonOperatorType: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
    nonDropdownComparisonOperatorType: PropTypes.string
}
ComparisonOperatorSelectOptions.defaultProps = {
    error: false,
    nonDropdownComparisonOperatorType: null
}
