import React, { forwardRef } from 'react';
import styles from './RoundLayout.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
export const RoundLayout = forwardRef(({ children, className }, ref) => {
    return (
        <div className={cx(styles.container, className)} ref={ref}>
            {children}
        </div>
    )
});

RoundLayout.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};
RoundLayout.defaultProps = {
    className: ''
}