import React from 'react';
import styles from './PricingCalculatorServices.module.css';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import { AttentionText } from 'shopify/modules/AttentionText/AttentionText';
import { Check } from 'shopify/icons/Check';

export const PricingCalculatorServices = () => {
    return (
        <div className={styles.container}>
            <TextPrimary
                text='With NeuCurrent platform you get tools to grow:'
                strong
            />

            <div className={styles.columns}>
                <div className={styles.column}>
                    <AttentionText
                        icon={<Check/>}
                        text='Omnichannel Communication Campaigns'
                    />
                    <AttentionText
                        icon={<Check/>}
                        text='Drag-and-drop Campaigns builder'
                    />
                    <AttentionText
                        icon={<Check/>}
                        text='Prebuilt (RFM) customer segmentation'
                    />
                </div>
                <div className={styles.column}>
                    <AttentionText
                        icon={<Check/>}
                        text='Customer Segmentation based on all customer events'
                    />
                    <AttentionText
                        icon={<Check/>}
                        text='Customer Retention Strategy Tool'
                    />
                    <AttentionText
                        icon={<Check/>}
                        text='Customer Retention (RFM) Analytics'
                    />
                </div>
                <div className={styles.column}>
                    <AttentionText
                        icon={<Check/>}
                        text='Product Personalisation for each customer'
                    />
                    <AttentionText
                        icon={<Check/>}
                        text='Campaign Analytics'
                    />
                    <AttentionText
                        icon={<Check/>}
                        text='Integrated Customer Profiles'
                    />
                </div>
            </div>
        </div>
    )
}