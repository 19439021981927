import React, { useState } from 'react';
import styles from 'shopify/components/OurProductsDropdown/OurProductsDropdown.module.css';
import classNames from 'classnames/bind';
import { DownArrow } from 'shopify/icons/DownArrow';

const cx = classNames.bind(styles);
export const OurProductsDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.container}>
            <p onClick={toggleDropdown} className={styles.dropdown}>
                Our products
                <span className={cx(styles.arrow, { [styles.open]: isOpen })}>
                    <DownArrow/>
                </span>
            </p>

            {isOpen &&
                <ul className={styles.menu}>
                    <li className={styles.item}>
                        <a href='https://www.neucurrent.com/product-overview' target='_blank' rel='noreferrer'>Product overview</a>
                    </li>
                    <li className={styles.item}>
                        <a href='https://www.neucurrent.com/customer-data-platform' target='_blank' rel='noreferrer'>Customer data platform</a>
                    </li>
                    <li className={styles.item}>
                        <a href='https://www.neucurrent.com/communication' target='_blank' rel='noreferrer'>Omnichannel communication platform</a>
                    </li>
                    <li className={styles.item}>
                        <a href='https://www.neucurrent.com/recommendation' target='_blank' rel='noreferrer'>Personalized recommendations</a>
                    </li>
                    <li className={styles.item}>
                        <a href='https://www.neucurrent.com/bigquery-data-warehouse' target='_blank' rel='noreferrer'>Big Query Data Warehouse</a>
                    </li>
                </ul>
            }
        </div>
    );
};