import {
    LOGIN_USER,
    LOGOUT_USER,
    SET_SIGN_UP
} from 'store/types';

const initialState = {
    isAuthenticated: false,
    user: {
        username: null,
        token: null,
        tokenExpireDate: null,
        sandboxMode: false
    },
    signUp: null
}

export default function auth(store = initialState, { type, payload }) {
    switch (type) {
        case LOGIN_USER:
            return {
                ...store, user: payload.data, isAuthenticated: true
            };
        case LOGOUT_USER:
            return {
                ...store, user: payload.defaultUsernameStore, isAuthenticated: false
            };
        case SET_SIGN_UP:
            return {
                ...store, signUp: payload.data
            };
        default:
            return store;
    }
}
