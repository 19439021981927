import React from 'react';

export const Logo = () => {
    return <svg width="134" height="32" viewBox="0 0 134 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.2485 32.0001C21.4739 32.0001 24.0887 29.2924 24.0887 25.9522C24.0887 22.612 21.4739 19.9043 18.2485 19.9043C15.023 19.9043 12.4082 22.612 12.4082 25.9522C12.4082 29.2924 15.023 32.0001 18.2485 32.0001Z" fill="url(#paint0_linear_2040_3964)"/>
        <path d="M27.6539 16.2983C30.7128 16.2983 33.1925 13.7304 33.1925 10.5627C33.1925 7.39506 30.7128 4.82715 27.6539 4.82715C24.595 4.82715 22.1152 7.39506 22.1152 10.5627C22.1152 13.7304 24.595 16.2983 27.6539 16.2983Z" fill="url(#paint1_linear_2040_3964)"/>
        <path d="M9.17328 17.2635C13.7768 17.2635 17.5087 13.399 17.5087 8.63176C17.5087 3.86457 13.7768 0 9.17328 0C4.56977 0 0.837891 3.86457 0.837891 8.63176C0.837891 13.399 4.56977 17.2635 9.17328 17.2635Z" fill="url(#paint2_linear_2040_3964)"/>
        <path d="M41.0879 21.1828H43.254V12.977L48.491 21.1828H50.6571V9.05859H48.491V17.2644L43.254 9.05859H41.0879V21.1828Z" fill="url(#paint3_linear_2040_3964)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M61.8161 17.6049C61.8709 17.2641 61.8709 16.9518 61.8709 16.6395C61.8709 13.942 60.0339 11.8125 57.3468 11.8125C54.1662 11.8125 52.6855 14.6235 52.6855 16.7247C52.6855 18.7974 54.1662 21.4948 57.3194 21.4948C57.7307 21.4948 60.4177 21.4665 61.5693 18.5703H59.1839C58.6629 19.2233 57.8952 19.4221 57.2097 19.4221C56.0855 19.4221 55.1807 18.7406 54.9065 17.6049H61.8161ZM54.9065 15.6741C55.2629 14.5951 56.1952 13.8569 57.3468 13.8569C58.4161 13.8569 59.3484 14.5099 59.65 15.6741H54.9065Z" fill="url(#paint4_linear_2040_3964)"/>
        <path d="M65.7929 14.5954V12.0967H63.6816V17.0088C63.6816 17.1508 63.6816 17.3212 63.6816 17.4915L65.7929 14.5954Z" fill="url(#paint5_linear_2040_3964)"/>
        <path d="M84.8482 12.5223C83.8611 10.194 81.8321 8.83105 79.4192 8.83105C75.2789 8.83105 73.2773 12.4087 73.2773 15.1061C73.2773 17.8887 75.2789 21.4664 79.3644 21.4664C81.9143 21.4664 83.8063 20.0183 84.7659 17.8887H82.2434C81.5031 18.8825 80.4611 19.3084 79.4192 19.3084C77.1708 19.3084 75.416 17.406 75.416 15.1345C75.416 13.005 77.0612 11.0458 79.3918 11.0458C80.7902 11.0458 81.7498 11.784 82.3256 12.5507H84.8482V12.5223Z" fill="url(#paint6_linear_2040_3964)"/>
        <path d="M69.3569 12.0967H71.4681V21.1828H69.5214V20.3593H69.494C68.7262 21.3531 67.7391 21.4951 67.2182 21.4951C65.0521 21.4951 64.2021 20.2174 63.873 18.9112L65.765 16.2706V16.7533C65.765 17.7755 65.8472 19.4507 67.5472 19.4507C69.2472 19.4507 69.3294 17.7755 69.3294 16.7533V12.0967H69.3569Z" fill="url(#paint7_linear_2040_3964)"/>
        <path d="M92.1699 12.0967H94.2812V21.1828H92.3344V20.3593H92.307C91.5393 21.3531 90.5522 21.4951 90.0312 21.4951C86.7135 21.4951 86.4941 18.4569 86.4941 17.0372V12.0967H88.6054V16.7249C88.6054 17.7471 88.6877 19.4223 90.3877 19.4223C92.0876 19.4223 92.1699 17.7471 92.1699 16.7249V12.0967Z" fill="url(#paint8_linear_2040_3964)"/>
        <path d="M96.4746 21.1825H98.5859V16.242C98.5859 15.7025 98.5859 13.8853 100.478 13.9137V11.8125C99.6278 11.7273 98.8601 12.1532 98.4488 12.9199H98.4214V12.0964H96.4746V21.1825Z" fill="url(#paint9_linear_2040_3964)"/>
        <path d="M104.125 21.1821H102.014V12.0961H103.96V12.9195H103.988C104.399 12.1244 105.167 11.7269 106.017 11.8121V13.9133C104.125 13.8849 104.125 15.7305 104.125 16.2416V21.1821Z" fill="url(#paint10_linear_2040_3964)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M116.326 17.6049C116.381 17.2641 116.381 16.9518 116.381 16.6395C116.381 13.942 114.544 11.8125 111.857 11.8125C108.676 11.8125 107.195 14.6235 107.195 16.7247C107.195 18.7974 108.676 21.4948 111.829 21.4948C112.24 21.4948 114.927 21.4665 116.079 18.5703H113.694C113.173 19.2233 112.405 19.4221 111.719 19.4221C110.595 19.4221 109.69 18.7406 109.416 17.6049H116.326ZM109.416 15.6741C109.773 14.5951 110.705 13.8569 111.857 13.8569C112.926 13.8569 113.858 14.5099 114.16 15.6741H109.416Z" fill="url(#paint11_linear_2040_3964)"/>
        <path d="M120.301 21.1826H118.189V12.0965H120.109V12.9199H120.136C120.41 12.5508 121.123 11.7842 122.549 11.7842C124.578 11.7842 125.976 13.2891 125.976 15.8445V21.1542H123.865V16.3272C123.865 14.2828 122.933 13.8285 122.11 13.8285C120.41 13.8285 120.301 15.5038 120.301 16.3272V21.1826Z" fill="url(#paint12_linear_2040_3964)"/>
        <path d="M128.473 21.1822H130.584V14.0269H131.845L133.161 12.0961H130.584V10.4209H128.473V21.1822Z" fill="url(#paint13_linear_2040_3964)"/>
        <defs>
            <linearGradient id="paint0_linear_2040_3964" x1="12.4307" y1="25.9525" x2="24.0897" y2="25.9525" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.3207" stopColor="#00C2A6"/>
                <stop offset="0.6531" stopColor="#00B5B1"/>
                <stop offset="0.9898" stopColor="#00A1C3"/>
                <stop offset="1" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2040_3964" x1="22.1092" y1="10.5532" x2="33.1786" y2="10.5532" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.3207" stopColor="#00C2A6"/>
                <stop offset="0.6531" stopColor="#00B5B1"/>
                <stop offset="0.9898" stopColor="#00A1C3"/>
                <stop offset="1" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint2_linear_2040_3964" x1="0.837891" y1="8.64551" x2="17.5352" y2="8.64551" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.3207" stopColor="#00C2A6"/>
                <stop offset="0.6531" stopColor="#00B5B1"/>
                <stop offset="0.9898" stopColor="#00A1C3"/>
                <stop offset="1" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint3_linear_2040_3964" x1="24.4545" y1="1.17836" x2="97.8831" y2="45.7393" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint4_linear_2040_3964" x1="27.0315" y1="-3.06935" x2="100.46" y2="41.4916" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint5_linear_2040_3964" x1="29.9343" y1="-7.8506" x2="103.363" y2="36.7102" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint6_linear_2040_3964" x1="33.9181" y1="-14.4172" x2="107.347" y2="30.1437" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint7_linear_2040_3964" x1="30.2067" y1="-8.30041" x2="103.635" y2="36.2605" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint8_linear_2040_3964" x1="36.6668" y1="-18.9449" x2="110.095" y2="25.616" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint9_linear_2040_3964" x1="38.7473" y1="-22.3737" x2="112.176" y2="22.1874" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint10_linear_2040_3964" x1="40.3193" y1="-24.9638" x2="113.748" y2="19.5972" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint11_linear_2040_3964" x1="42.4639" y1="-28.4982" x2="115.893" y2="16.0629" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint12_linear_2040_3964" x1="45.0954" y1="-32.8358" x2="118.524" y2="11.7252" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
            <linearGradient id="paint13_linear_2040_3964" x1="47.8447" y1="-37.3646" x2="121.273" y2="7.19631" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C4C4C4"/>
                <stop offset="0.0001" stopColor="#00C6A2"/>
                <stop offset="0.49" stopColor="#00C6A2"/>
                <stop offset="0.502" stopColor="#00A0C4"/>
                <stop offset="0.5218" stopColor="#00A0C4"/>
                <stop offset="0.8356" stopColor="#00A0C4"/>
            </linearGradient>
        </defs>
    </svg>

}