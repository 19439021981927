import React from 'react';
import styles from './Slider.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
const calculateBackground = (value, min, max) => {
    const percent = ((value - min) / (max - min)) * 100; // Рассчитываем процент
    return `linear-gradient(to right, #00C6A2 ${percent}%, #F1F5F9 ${percent}%)`;
};
export const Slider = ({ value, onChange, min, max, className }) => {
    return (
        <div className={cx(styles.container, className)}>
            <input
                type='range'
                min={min}
                max={max}
                value={value}
                onChange={onChange}
                className={styles.slider}
                style={{
                    background: calculateBackground(value, min, max)
                }}
            />
        </div>
    )
};

Slider.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    className: PropTypes.string
};
Slider.defaultProps = {
    className: ''
};