import React from 'react';
import styles from './Checkbox.module.css';
import PropTypes from 'prop-types';

export const Checkbox = ({ onChange, isChecked }) => {
    return (
        <>
            <input
                id='checkbox'
                type='checkbox'
                checked={isChecked}
                onChange={onChange}
                className={styles.container}
                readOnly
            />
            <label htmlFor='checkbox'/>
        </>
    )
}
Checkbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool
}
Checkbox.defaultProps = {
    isChecked: false
}