import React from 'react';
import styles from './PricingCalculatorHeader.module.css';
import PropTypes from 'prop-types';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { KeyValueText } from 'shopify/components/TextStrong/KeyValueText';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import classNames from 'classnames/bind';
import { toString } from 'lodash';
import { NumberUse } from 'lib/NumberUse';

const cx = classNames.bind(styles);
export const PricingCalculatorHeader = ({ selected }) => {
    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <TextSecondary
                    text='Pricing Calculator'
                />
                <KeyValueText
                    label='Number of customers:'
                    text={selected.key === 'custom'
                        ? 'More then 700k'
                        : 'Up to ' + toString(NumberUse.zippedFormatNumber(selected.key))
                    }
                    className={styles.customers}
                />
            </div>
            <div className={cx(styles.column, styles.directionEnd)}>
                <TextSecondary
                    text='All Services price'
                />
                <TextPrimary
                    text={selected.key === 'custom'
                        ? selected.price
                        : `$${selected.price} / mo`}
                    className={styles.price}
                    strong
                />
            </div>
        </div>
    )
};

PricingCalculatorHeader.propTypes = {
    selected: PropTypes.object.isRequired
};