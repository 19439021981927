import React, { useEffect, useState } from 'react';
import styles from 'shopify/modules/Countdown/Countdown.module.css';
import { SandClockIcon } from 'shopify/icons/SandClockIcon';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import PropTypes from 'prop-types';

export const Countdown = ({ reset, onCountdownEnd, onResetComplete, localeStorageKey }) => {
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        let endTime;

        const initializeCountdown = () => {
            const now = new Date().getTime();
            endTime = now + 3 * 60 * 1000; // Устанавливаем таймер на 3 минуты
            localStorage.setItem(localeStorageKey, endTime);
            setTimeLeft(endTime - now);
            onResetComplete(); // Сообщаем родительскому компоненту, что таймер перезапущен
        };

        const savedEndTime = localStorage.getItem(localeStorageKey);

        if (reset) {
            console.log('reset');
            initializeCountdown();
            onResetComplete(); // Сбрасываем флаг reset после запуска
        } else if (savedEndTime) {
            console.log('Restore timer from localhost');
            const now = new Date().getTime();
            endTime = parseInt(savedEndTime, 10);
            setTimeLeft(Math.max(0, endTime - now));
        } else {
            console.log('start');
            initializeCountdown();
            onResetComplete(); // Сбрасываем флаг reset
        }

        const updateCountdown = () => {
            const remaining = endTime - new Date().getTime();

            if (remaining > 0) {
                console.log('tik');
                setTimeLeft(remaining);
            } else {
                console.log('Timer end');
                clearInterval(timer);
                setTimeLeft(0);
                localStorage.removeItem(localeStorageKey);
                onCountdownEnd(); // Вызываем onCountdownEnd только один раз
            }
        };

        const timer = setInterval(updateCountdown, 1000);

        return () => clearInterval(timer);
    }, [reset]); //eslint-disable-line

    const formatTime = (milliseconds) => {
        const minutes = Math.floor(milliseconds / (60 * 1000));
        const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className={styles.container}>
            <div className={styles.clock}>
                <SandClockIcon/>
            </div>
            <TextPrimary
                text={`Resend in ${formatTime(timeLeft)}`}
            />
        </div>
    );
};

Countdown.propTypes = {
    reset: PropTypes.bool.isRequired,
    onCountdownEnd: PropTypes.func.isRequired,
    onResetComplete: PropTypes.func.isRequired,
    localeStorageKey: PropTypes.string.isRequired
};
