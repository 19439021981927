import React from 'react';
import styles from 'shopify/components/TextStrong/KeyValueText.module.css';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);
export const KeyValueText = ({ label, text, className }) => {
    return (
        <p className={cx(styles.container, className)}>
            <span>{label}</span>
            {text}
        </p>
    )
};

KeyValueText.propTypes = {
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string
};
KeyValueText.defaultProps = {
    className: ''
};