export const ukrainianTranslation = {
    auth: {
        // Auth login
        'GoogleAuthMessage': 'Вхід через обліковий запис Google',
        'Login': 'Логін',
        'Password': 'Пароль',
        'RegistrationLink': 'Реєстрація',
        'InputValidationRequiredMessage': 'Поле обов\'язково для введення',
        'BrowserListMessage': 'Використовуйте один із веб-браузерів із цього списку:',
        // Auth registration
        'RegistrationAnnotationMessage': 'Надайте вашу пошту та короткий опис. Ми зв\'яжемось з Вами!',
        'EmailAddress': 'Адреса електронної пошти',
        'UserRegistrationMessage': 'Опис',
        'LoginLink': 'Вхід',
        'AcceptButton': 'Далі',
        // Auth thank you pop-up
        'ThankYouPopUpMessage': 'Дякуємо, ми з вами скоро зв\'яжемося!',
        'ReturnButton': 'Повернутись',
    },
    mainLeftSideBar: {
        'Retention': 'Утримання',
        'Campaigns': 'Кампанії',
        'Templates': 'Шаблони',
        'Integrations': 'Інтеграції',
        'Recommendations': 'Рекомендації',
        'Audiences': 'Аудиторії',
        'Analytics': 'Аналітика',
        'Instructions': 'Інструкції'
    },
    mainHeader: {
        'LaunchScenarioMessage': 'Чи готові залучити своїх клієнтів?',
        'LaunchScenarioButtonName': 'Запуск сценарію'
    },
    mainHeaderBreadcrumbs: {
        'home': 'Головна',
        'dashboard': 'Дошка',
        'campaigns': 'Кампанії',
        'goal': 'Ціль',
        'conversion': 'Конверсія',
        'cross-cell': 'Перехресний продаж',
        'retention': 'Нагадування',
        'all-scenarios': 'Усі сценарії',
        'metrics': 'Метрики',
        'custom-webhook': 'Request Body',
        'templates': 'Шаблони',
        'edit-template': 'Редактор шаблону',
        'all-templates': 'Всі шаблони',
        'email': 'Email',
        'viber': 'Viber',
        'sms': 'Sms',
        'consent': 'Згоди',
        'markup': 'Розмітка',
        'recommendations': 'Рекомендації',
        'units': 'Юніти',
        'type': 'Тип',
        'webpushnotify': 'Веб пуші',
        'mobpushnotify': 'Мобільні пуші',
        'website': 'Вебсайт банери',
        'folder': 'Тека',
        'audiences': 'Аудиторії',
        'analytics': 'Аналітика',
        'client': 'Клієнт',
        'integrations': 'Інтеграції',
        'allEvents': 'Усі події',
        'addNewField': 'Додати поле',
        'uploadOrders': 'Завантаження замовлень',
        'uploadOrderItems': 'Завантаження позиції товарів',
        'uploadCustomers': 'Завантаження клієнтів',
        'uploadProducts': 'Завантаження продуктів',
        'communicationFrequencyPolicies': 'Налаштування частоти комунікацій',
        'communicationConsents': 'Згоди',
        'master': 'Мастер',
        'masterBased': 'Заснований на майстері',
        'billing': 'Ціни'
    },
    searchInput: {
        'SearchInputPlaceholder': 'Пошук за назвою',
        'SearchSegmentPlaceholder': 'Пошук сегменту',
        'SearchClientPlaceholder': 'Пошук клієнтів',
        'SearchEventNamePlaceholder': 'Пошук за назвою події',
        'SearchPlaceholder': 'Пошук',
        'SearchProductPlaceholder': 'Пошук продукту за назвою чи артикулом',
    },
    filter: {
        'ScenariosStatusTitle': 'Статус сценарію',
        'TemplateStatusTitle': 'Статус шаблону',
        'TemplateToolTitle': 'Інструмент шаблону',
        'SelectWebsiteBannerInputs': 'Доступні типи полів',
        'AllButton': 'Всі',
        'ClearButton': 'Очистити',
        'Active': 'Активно',
        'Stopped': 'Зупинені',
        'Ready': 'Готовий',
        'Draft': 'Чернетка',
        'Name': 'Ім\'я',
        'Email': 'Пошта',
        'Age': 'Вік',
        'Phone': 'Номер телефону',
        'ClientEvents': 'Client events',
        'ProductEvents': 'Product events',
        'Yes': 'Так',
        'No': 'Ні',
        'TopicFilter': 'Тема',
        'InternalFilter': 'Внутрішній',
        'TemplatesTypeFilter': 'Тип',
        'TemplatesLanguageFilter': 'Мова',
        'CrossSell': 'Перехресний продаж',
        'WebsiteConversion': 'Конверсія сайту',
        'TransactionalMessages': 'Транзакційні повідомлення',
        'PersonalisedDigests': 'Персоналізовані дайджести',
        'MastersTemplateFilter': 'Мастери',
        'CodeEditorTemplateFilter': 'Редактор коду',
    },
    scenariosTable: {
        'TableColumnName': 'Ім\'я',
        'TableColumnLastChange': 'Останні зміни',
        'TableColumnMethods': 'Дії',
        'ConversionNoScenariosMessage': 'Сценарії конверсії відсутні. Створіть свій перший сценарій.',
        'CrossSellNoScenariosMessage': 'Сценарії перехресного продажу відсутні. Створіть свій перший сценарій.',
        'RetentionNoScenariosMessage': 'Сценарії нагадувань відсутні. Створити свій перший сценарій.',
        'AllScenariosNoScenariosMessage': 'Сценарії відсутні. Створити свій перший сценарій.'
    },
    segmentsTable: {
        'TableColumnName': 'Ім\'я',
        'TableColumnLastChange': 'Останні зміни',
        'TableColumnMethods': 'Дії',
        'TableColumnAmountClient': 'Кількість клієнтів',
        'TableColumnEmail': 'Емейл',
        'TableColumnTelephone': 'Телефон',
        'TableColumnUserId': 'Id користувача',
        'TableColumnEventType': 'Тип',
        'TableColumnEvent': 'Подія',
        'TableColumnDate': 'Дата',
        'TableColumnTime': 'Час',
        'NoName': '',
        'NoRecordToDisplay': 'Немає даних для відображення'
    },
    scenarioStatuses: {
        'Active': 'Активно',
        'Stopped': 'Зупинені',
        'Ready': 'Готовий',
        'Draft': 'Чернетка',
        'Library': 'Бібліотечний сценарій'
    },
    templateStatuses: {
        'Draft': 'Чернетка',
        'Ready': 'Готовий',
    },
    dashboardHeader: {
        'RecommendationUnitStatusTitle': 'Статус юніту:',
        'ScenariosStatusTitle': 'Статус сценарію:',
        'TemplateStatusTitle': 'Статус шаблону:',
        'DesignNavLink': 'Дизайн',
        'MetricsNavLink': 'Метрики',
        'RequestBodyNavLink': 'Request Body',
        'SqlTriggerNavLink': 'SQL Запит',
        'CancelChangesButton': 'Відмінити зміни',
        'SaveChangesButton': 'Зберегти',
        'TestButton': 'Тест',
    },
    actionSidebars: {
        'actionName': 'Назва події *',
        'actionSubName': 'Підназва',
        'maxAudienceSize': 'Максимальний розмір аудиторії',
        'NewAttributeFilterButton': '+ Новий фільтр за атрибутами',
        'NewAttributeFilterName': 'Фільтр подій',
        'EventAttributeNameLabel': 'Назва атрибуту події *',
        'ComparisonOperatorLabel': 'Оператор порівняння *',
        'ComparisonValueLabel': 'Значення для порівняння',
        'TimeFieldLabel': 'Місцевий час',
        'NewConditionButton': '+ Створити нову умову',
        'NewConditionName': 'Умова',
        'SegmentationFilterLabel': 'Фільтр по сегментації',
        'SegmentationFilterEvent': 'Подія',
        'SegmentationFilterAttribute': 'Атрибути',
        'SegmentationFilterMetric': 'Метрики',
        'countDays': 'Кількість днів *',
        'countHours': 'Кількість годин *',
        'LastTimeIntervalLabel': 'За останній час',
        'CurrentTimeIntervalLabel': 'Певний період часу',
        'AtScriptStartName': 'З моменту запуску сценарію',
        'MetricAttributeNameLabel': 'Назва метрики *',
        'ClientAttributeNameLabel': 'Назва атрибуту клієнта *',
        'AggregationFunctionNameLabel': 'Функція агрегування',
        'AggregationColumnNameLabel': 'Колонка агрегування *',
        'waitInterval': 'Кількість хвилин *',
        'waitToDate': 'Метрика очікування (днів)',
        'dateOffset': 'Зміщення днів',
        'waitToTime': 'Метрика очікування годин',
        'timeOffset': 'Зміщення часу',
        'controlGroup': 'Контрольна група:',
        'aGroupValue': 'A:',
        'bGroupValue': 'Б:',
        'shareCount': 'Частка клієнтів далі в сценарії *',
        'countClients': 'Максимальна кількість клієнтів далі в сценарії *',
        'countTimeIntervalNumber': 'Впродовж *',
        'evalTimeInterval': 'Розподілити рівномірно',
        'CountFunnelTabButton': 'Кількість',
        'ShareFunnelTabButton': 'Частка',
        'RepeatTabButton': 'Repeat',
        'ScheduleTabButton': 'Schedule',
        'minutesTimeInterval': 'Хвилин',
        'hoursTimeInterval': 'Годин',
        'daysTimeInterval': 'Днів',
        'weeksTimeInterval': 'Тижнів',
        'monthTimeInterval': 'Місяців',
        'EventConditionSplitTabButton': 'Події',
        'ClientConditionSplitTabButton': 'Клієнту',
        'ProductConditionSplitTabButton': 'Товару',
        'NewProductFilterButton': '+ Новий фільтр по товару',
        'NewProductFilterName': 'Фільтр товару',
        'ColumnNameOfProductFromEventLabel': 'ID товару з події *',
        'ColumnNameFromEventLabel': 'Атрибут товару з події *',
        'ColumnNameFromCatalog': 'Атрибут товара з каталогу *',
        'ReceiverContactEmailLabel': 'Email одержувача',
        'ReceiverContactPhoneLabel': 'Номер телефону одержувача',
        'emailMaxCount': 'Максимальна кількість відправок клієнту *',
        'MinDispatchTime': 'Мінімальний час відправлення *',
        'MaxDispatchTime': 'Максимальний час відправлення *',
        'NewJsonHeaderButton': '+ Новий Header',
        'impressionsNumber': 'Кількість показів',
        'DisplayDateLabel': 'Крайня дата відображення',
        'NewUrlButton': '+ Новий Url',
        'MaxShowing': 'Максимальна кількість показів *',
        'RangeOfDatesInputLabel': 'Проміжок часу *',
        'templateCache': 'Кешувати шаблон',
        'ConditionName': 'Назва умови *',
        'Conditions': 'Умова',
        'Segments': 'Сегменти',
        'MadePurchaseSilmplifiedSettings': 'Здійснив покупку',
        'AddToBasketSilmplifiedSettings': 'Додав до кошика',
        'ViewedProductSilmplifiedSettings': 'Переглянув товар',
        'ReadEmailSilmplifiedSettings': 'Читав імейл',
        'ReadViberSilmplifiedSettings': 'Читав viber',
        'ReadSmsSilmplifiedSettings': 'Читав sms',
        'TimeInterval': 'Часовий проміжок *',
        'EmailLabel': 'Пошта *',
        'PhoneLabel': 'Телефон *',
        'CommunicationPolicyLabel': 'Налаштування частоти комунікацій *'
    },
    templates: {
        'TabsTemplatesEmailTitle': 'Email шаблони',
        'TabsTemplatesSmsTitle': 'SMS шаблони',
        'TabsTemplatesViberTitle': 'Viber шаблони',
        'TabsTemplatesConsentTitle': 'Сторінка згоди',
        'TabsTemplatesWebsiteBuilderTitle': 'Конструктор банерів',
        'TabsTemplatesMobilePushTitle': 'Mobile Push шаблони',
        'TabsTemplatesWebPushTitle': 'Web Push шаблони',
        'SenderNameLabel': 'Ім\'я відправника *',
        'SenderPhoneLabel': 'Номер відправника *',
        'SenderEmailLabel': 'Пошта відправника *',
        'SenderMsgTopic': 'Тема повідомлення *',
        'ButtonTextLabel': 'Текст кнопки',
        'FrontButtonTextLabel': 'Текст кнопки лицьової сторони банера *',
        'FrontButtonColorLabel': 'Колір кнопки лицьової сторони банера (hex) *',
        'BackButtonTextLabel': 'Текст кнопки зворотного боку банера *',
        'BackButtonColorLabel': 'Колір кнопки зворотного боку банера (hex) *',
        'BackSideBannerTitleLabel': 'Заголовок форми зворотнього зв\'язку *',
        'BackSideBannerThanksLabel': 'Повідомлення з подякою *',
        'BodyTextLabel': 'Повідомлення *',
        'UrlButtonLabel': 'URL кнопки',
        'UrlButtonLabelRequired': 'URL кнопки *',
        'TextAreaLabel': 'Текст *',
        'UploadImageLabel': 'Зображення 200x400px',
        'UrlImageLabel': 'URL зображення або Jinja код',
        'DeepLinkUrlLabel': 'Глибоке посилання',
        'UploadBannerLabel': 'Завантажте банер *',
        'CommunicationProviderLabel': 'Провайдер комунікації',
        'ConsentCategoryLabel': 'Consent категорія',
        'TemplateSidebarTitle': 'Шаблон',
        'UtmTagsLabel': 'UTM-мітки',
        'BannerPositionLabel': 'Розташування банера',
        'BannerSizeLabel': 'Розмір банера',
        'TitleLabel': 'Заголовок *',
        'TextLabel': 'Текст *',
        'LinkPushLabel': 'Посилання для push *',
        'TextButtonLabel': 'Текст кнопки',
        'LinkLabel': 'Посилання',
        'TtlLabel': 'Час показу',
        'TtlSeconds': '(секунди)',
        'UploadPictureLabel': 'Зображення *',
        'UploadLogoLabel': 'Логотип *',
        'UploadLogoSize': 'до 128 kB',
        'UploadChromeLogoLabel': 'Зображення для Google Chrome*',
        'UploadChromeLogoSize': 'до 5.24 MB',
        'EmailLabel': 'Пошта *',
        'PhoneLabel': 'Телефон *',
        'TokenLabel': 'Токен *',
        'TitleSendTest': 'Відправити тест',
        'YourMasterTemplates': 'Ваші майстер шаблони',
        'YourFolders': 'Ваші теки',
        'YourMasterTemplatesDefaultText': 'Для більш швидкої роботи: створюйте власні шаблони листів та використовуйте їх у будь-який час – це просто!',
        'YourFoldersDefaultText': 'Збережіть файл, ніколи його не втратьте!',
        'ImagesInThisFolder': 'зображення(-нь) у цій теці',
        'AddCustomAttributeButton': '+ Додати спеціальний атрибут',
        'AttributeNameLabel': 'Ім\'я атрибуту',
        'AttributeValueLabel': 'Значення атрибуту',
    },

    chartsTitle: {
        'ChartClientTitle': 'Клієнти',
        'ChartScenarioConversionTitle': 'Коефіцієнт конверсії',
        'ChartScenarioRevenueTitle': 'Дохід сценарію',
        'ChartTemplateRevenueTitle': 'Дохід шаблону',
        'ChartSendConversionTitle': 'Відправлено/перейшли на сайт',
        'ChartNumberOfPurchasesTitle': 'Кількість покупок',
    },
    metrics: {
        'ScenarioMetricsTitle': 'Метрики сценарію',
        'CommunicationsMetricsTitle': 'Метрики комунікацій',
        'TemplateMetricsTitle': 'Метрики шаблону',
        'MetricsScenarioEntered': 'Увійшли до сценарію',
        'MetricsCompletedPurchases': 'Зробили покупку',
        'MetricsConversionRate': 'Коефіцієнт конверсії',
        'MetricsRevenue': 'Дохід',
        'MetricsSendMessages': 'Надіслано повідомлень',
        'MetricsSiteConversion': 'Перейшли на сайт',
        'MetricsABGroup': 'А/Б група',
        'MetricsAllClients': 'Усього клієнтів у сценарії',
        'MetricsMessage': 'Повідомлення',
        'MetricsConversion': 'Конверсії',
        'MetricsOpenedMessages': 'Відкрито повідомлення',
        'MetricsConversionMessages': 'Переходи з повідомлення',
        'MetricsReceivedMessages': 'Доставлено повідомлень',
        'MetricsUnsubscribedClients': 'Клієнтів відписалося',
        'MetricsTemplateRevenue': 'Дохід шаблону',
        'MetricsClientMoved': 'Клієнтів перейшли',
        'MetricsThrown': 'Брошено',
    },
    metricsTable: {
        'TableColumnActionName': 'Ім\'я дії',
        'TableColumnTemplateName': 'Ім\'я шаблону',
        'TableColumnMsgSend': 'Відправлено',
        'TableColumnDelivered': 'Доставлено',
        'TableColumnOpened': 'Відкрито',
        'TableColumnConversion': 'Конверсія',
        'TableColumnUnsubscribed': 'Відписалися',
        'TableColumnRevenue': 'Сума покупок',
        'TableNoDataMessage': 'Дані відсутні',
        'TableColumnClicked': 'Перейшли',
        'TableColumnPurchased': 'Придбано',
    },
    consentHeader: {
        'Markup': 'Розмітка',
        'Preview': 'Превью',
        'CancelChangesButton': 'Відмінити зміни',
        'SaveButton': 'Зберегти',
    },
    popUpsSystemMessages: {
        'LogoutTitle': 'Підтвердьте вихід із облікового запису',
        'ErrorAlerterTitle': 'Упс.. щось пішло не так 🧐 ',
        'ImageUploadAlerter': 'Переконайтеся, що завантажується зображення менше 5.00 Мегабайт \n і має допустимий формат .jpg, .gif, .png, .pictures',
        'FillableAlerterLabel': 'Альтернативна назва картинки',
        'FillableAlerterPlaceholder': '* Необов\'язкове поле',
        'FillableAlerterButton': 'Завантажити зображення',
        'SaveScenariosTitle': 'Додавання сценаріїв',
        'SaveScenariosPreventUpdateTitle': 'Неможливо зберегти експеримент без сценаріїв',
        'SaveScenariosPreventUpdateText': 'Необхідно мати хоча б один занедбаний сценарій',
        'SaveScenariosConfirmUpdateText': 'Оновлено список увімкнених сценаріїв',
        'DeleteScenarioTitle': 'Видалення сценарію',
        'DeleteScenarioPreventText': 'Неможливо видалити сценарій, який використовується в експерименті. Щоб видалити сценарій, його потрібно вимкнути.',
        'DeleteScenarioConfirmText': 'Ви впевнені, що хочете видалити цей сценарій?',
        'CancelChangesScenarioTitle': 'Скасування змін сценарію',
        'CancelChangesScenarioText': 'Всі зміни успішно скасовані!',
        'ChangeScenarioNameTitle': 'Зміна імені сценарію',
        'ChangeScenarioNameText': 'Увага! Зміни набудуть чинності після збереження сценарію!',
        'UpdateScenarioTitle': 'Збереження сценарію',
        'UpdateScenarioText': 'Сценарій збережено в базі',
        'CloseEditScenarioTitle': 'У поточний сценарій було внесено зміни!',
        'CloseEditScenarioText': 'Ви хочете залишити сторінку редагування сценарію. Вийти без збереження?',
        'SaveTemplateTitle': 'Збереження шаблону',
        'SaveTemplateText': 'Шаблон успішно збережено!',
        'ChangeTemplateNameTitle': 'Зміна імені шаблону',
        'ChangeTemplateNameText': 'Увага! Зміни набудуть чинності після збереження шаблону!',
        'DeleteTemplateTitle': 'Видалення шаблону',
        'DeleteTemplateText': 'Ви впевнені, що хочете видалити цей шаблон?',
        'DeleteTemplatePreventText': 'Неможливо видалити шаблон, який використовується у сценарії. Щоб видалити шаблон, його потрібно вимкнути.',
        'CopyTemplateTitle': 'Копіювання шаблону',
        'CopyTemplateText': 'Копія шаблону успішно створена',
        'DefaultPopUpsTitle': 'Титул за замовчуванням',
        'DefaultPopUpsText': 'За замовчуванням',
        'StartExperimentTitle': 'Запуск експерименту',
        'StartExperimentText': 'Експеримент успішно запущений!',
        'SelectField': 'Вибір поля',
        'WhereToUploadImage': 'Виберіть куди додати зображення',
        'DeleteRecommendationUnitTitle': 'Видалення рекомендаційного юніту',
        'DeleteRecommendationUnitText': 'Ви впевнені, що хочете видалити цей юніт?',
        'ChangeUnitNameTitle': 'Зміна імені юніта',
        'ChangeUnitNameText': 'Увага! Зміни набудуть чинності після повного збереження юніту!',
        'UpdateUnitTitle': 'Збереження юніту',
        'UpdateUnitText': 'Юніт успішно збережено в базі',
        'DeleteAbTestGroupTitle': 'Видалення А/Б тест групи',
        'DeleteAbTestGroupText': 'Ви впевнені, що хочете видалити цю А/Б тест групу?',
        'ConnectWebsiteBannerScript': 'Підключення скрипта до сайту',
        'ConnectWebsiteBannerScriptMessage': 'Скопіюйте та вставте посилання скрипта, на Ваш сайт, перед тегом body, що закривається, на головній сторінці.',
        'CancelChangesTemplateTitle': 'Скасувати зміни шаблону',
        'CancelChangesTemplateText': 'Ви впевнені, що хочете скасувати зміни у шаблоні?',
        'CloseChangesTemplateTitle': 'Закриття редактора шаблону',
        'CloseChangesTemplateText': 'Не вдалось змінити шаблон. Закрити без збереження?',
        'DeleteSegmentTitle': 'Видалення сегмента',
        'DeleteSegmentText': 'Ви впевнені, що хочете видалити цей сегмент?',
        'CopySegmentTitle': 'Копіювання сегмента',
        'CopySegmentText': 'Копія сегмента успішно створена',
        'SaveSegmentTitle': 'Збереження сегмента',
        'SaveSegmentText': 'Сегмент успішно збережений',
        'SaveEditSegmentTitle': 'Зачекайте, поки зміни набудуть чинності',
        'SaveEditSegmentText': 'При збереженні змін у сегменті потрібен час для обробки даних. Процес може становити кілька хвилин.',
        'DeleteIntegrationEventTitle': 'Видалення події',
        'DeleteIntegrationEventText': 'Ви впевнені, що хочете видалити цю подію?',
        'DeleteIntegrationFieldTitle': 'Видалення поля',
        'DeleteIntegrationFieldText': 'Ви впевнені, що хочете видалити це поле?',
        'WarningTitle': 'Попередження',
        'WarningSaveEventSchemaText': 'Ви додаєте поля або поля запису, що повторюються, в схему подій. Ви не зможете завантажувати дані CSV для таких подій',
        'DeletePolicyTitle': 'Видалення policy',
        'DeletePolicyText': 'Ви впевнені, що хочете видалити цей policy?',
        'UpdatedPolicyTitle': 'Збереження policies',
        'UpdatedPolicyText': 'Policies успішно збережено в базі!',
        'AddImageTitle': 'Додати зображення',
        'CreateNewFolder': 'Створити нову теку',
        'CreateNewMaster': 'Створити новий майстер',
        'FolderName': 'Ім\'я теки',
        'MasterName': 'Ім\'я майстера',
        'UploadSelectedImages': 'Завантаження зображень...',
        'Of': 'з',
        'Images': 'зображень',
        'DeleteModalTitle': 'Ви впевнені, що бажаєте видалити?',
        'DeleteFolderLabel': 'Введіть ім\'я теки для підтвердження',
        'DeleteMasterLabel': 'Введіть ім\'я мастура для підтвердження',
        'InvalidOrEmptyFolderName': 'Неприпустиме або пусте ім\'я теки',
        'InvalidOrEmptyMasterName': 'Неприпустиме або пусте ім\'я мастера',
        'ImageUploadTechnicalMessage': 'Усі зображення більше 5.24 МБ будуть автоматично ігноровані. Максимальна кількість завантажуваних зображень - 10.',
        'InputRequiredField': "Обов'язкове поле",
        'CreateEmailTemplateModalTitle': 'Створити новий шаблон',
        'CreateEmailTemplateModalCodeEditorTitle': 'Через редактор коду',
        'CreateEmailTemplateModalCodeEditorDesc': 'Використовуйте HTML та JINJA код.',
        'CreateEmailTemplateModalMasterTitle': 'Через майстер',
        'CreateEmailTemplateModalMasterDesc': 'Використовуйте нашу бібліотеку мастерів',
        'SelectMasterTemplateModalTitle': 'Бібліотека майстерів',
        'SelectMasterTemplateModalDesc': 'Використовуйте нашу бібліотеку шаблонів',
        'CreateConsentModalInput1Label': 'Ім\'я *',
        'CreateConsentModalInput2Label': 'Опис *',
    },
    validationErrorMessages: {
        'CheckboxValidationRequired': 'Необхідно вибрати хоча б один параметр',
        'InputValidationRequiredMessage': 'Поле є обов\'язковим для введення',
        'SelectValidationRequiredMessage': 'Виберіть одну опцію зі списку',
        'PasswordValidationMessage': 'Пароль повинен бути більше 8 символів',
        'EmailValidationMessage': 'Введена недійсна пошта',
        'InputUnacceptableSymbolsValidationMessage': 'У полі присутні неприпустимі символи',
        'PositiveNumberInputValidationMessage': 'Число має бути більшим за нуль',
        'ZeroNumberInputValidationMessage': 'Ніль неприпустиме значення. Якщо це поле вам не потрібно - залиште його порожнім',
        'UrlAddressInputValidationMessage': 'Введіть правильну URL-адресу',
        'EmailAddressInputValidationMessage': 'Введіть коректну електронну адресу',
        'PhoneInputValidationMessage': 'Це поле має бути від 7 до 14 символів',
        'InputTagsValuesOnlyNumericMessage': 'Допустимі лише числові значення',
        'UserDoesNotExist': 'Користувач не існує',
        'Unauthorized': 'Неправильний пароль',
        'TemplateValidationMessage': 'Необхідно вибрати шаблон',
        'ChooseAtLeastOneOption': 'Необхідно вибрати хоча б один параметр',
        'SearchUserMessage': 'Необхідно вибрати атрибут із списка'
    },
    actionArrows: {
        'positiveMiddleLabel': 'Так',
        'negativeMiddleLabel': 'Ні'
    },
    websiteBanner: {
        'NameInputLabel': 'Ім\'я *',
        'EmailInputLabel': 'Електронна пошта *',
        'PhoneInputLabel': 'Номер телефону *',
        'AgeInputLabel': 'Вік *',
        'DefaultButtonTextLabel': 'Перейти',
        'DefaultFeedbackTitleMessage': 'Будь ласка, заповніть усі поля нижче',
        'DefaultFeedbackThanksMessage': 'Дякую! Ми скоро зв\'яжемося з вами!',
        'DefaultFeedbackButtonText': 'Надіслати',
    },
    tableNoData: {
        'OopsLabel': 'Упс',
        'ScenarioListEmptyLabel': 'Сценарії відсутні',
        'CreateScenarioLabel': 'Створити сценарій',
        'ConditionListEmptyLabel': 'Немає умов',
        'CreateConditionLabel': 'Додати нову умову',
        'SegmentsListEmptyLabel': 'Сегменти відсутні',
        'CreateNewSegment': 'Додати новий сегмент',
        'ClientsSearchListLabel': 'Знайдіть клієнтів для додавання в сегмент',
        'ClientsEmptyListLabel': 'Клієнти відсутні',
        'AddClientLabel': 'Додати клієнта',
    },
    default: {
        'EnglishLang': 'English',
        'RussianLang': 'Русский (Украина)',
        'UkrainianLang': 'Українська',
        'PolishLang': 'Polski',
        'LoadingMessage': 'Завантаження',
        'SaveButtonTitle': 'Зберегти',
        'CancelButtonTitle': 'Скасувати',
        'SkipButtonTitle': 'Пропустити',
        'BackButtonTitle': 'Назад',
        'AcceptButtonTitle': 'Підтвердити',
        'ConfirmButtonTitle': 'Підтвердити',
        'ChoiceButtonTitle': 'Вибрати',
        'ReturnButtonTitle': 'Повернутися',
        'UploadButtonTitle': 'Завантажити',
        'CreatedButtonTitle': 'Створити',
        'CollapseAllButtonTitle': 'Згорнути все',
        'YesButtonTitle': 'Так',
        'NoButtonTitle': 'Ні',
        'DateInputViewerLabel': 'Певний час *',
        'EditButtonTitle': 'Редагувати',
        'DeleteButtonTitle': 'Видалити',
        'CopyButtonTitle': 'Копіювати',
        'LaunchButtonTitle': 'Запустити',
        'SendButtonTitle': 'Відправити',
        'StatisticButtonTitle': 'Статистика',
        'ActionErrorTitle': 'Помилка!',
        'ActionSaveErrorMessage': 'Необхідно заповнити всі необхідні поля в налаштуваннях цього Action.',
        'MondayShortcut': 'Пн',
        'TuesdayShortcut': 'Вт',
        'WednesdayShortcut': 'Ср',
        'ThursdayShortcut': 'Чт',
        'FridayShortcut': 'Пт',
        'SaturdayShortcut': 'Сб',
        'SundayShortcut': 'Нд',
        'MonthJanuary': 'Січень',
        'MonthFebruary': 'Лютий',
        'MonthMarch': 'Березень',
        'MonthApril': 'Квітень',
        'MonthMay': 'Травень',
        'MonthJune': 'Червень',
        'MonthJuly': 'Липень',
        'MonthAugust': 'Серпень',
        'MonthSeptember': 'Вересень',
        'MonthOctober': 'Жовтень',
        'MonthNovember': 'Листопад',
        'MonthDecember': 'Грудень',
        'ActionTemplates': 'Шаблони сценарію',
        'ChromeImage': 'Зображення для Chrome',
        'WebPushLogo': 'Web push логотип',
        'FirstButton': 'Перша кнопка',
        'SecondButton': 'Друга кнопка',
        'ActionTemplatePages': 'Список шаблонів',
        'Of': 'із',
        'Close': 'Закрити',
        'AddButtonLabel': 'Створити',
        'UploadFromPc': 'З комп\'ютера',
        'AddNew': 'Додати',
        'BackToTemplatesButton': 'Назад до Шаблонів',
        'DeleteFolderButton': 'Видалити теку',
        'DeleteButton': 'Видалити',
        'ApplyAndEditButton': 'Прийняти та редагувати',
        'DuplicateAndEdit': 'Дублювати та редагувати',
        'LaunchExperimentButton': 'Запустити експеримент'
    },
    unitStatuses: {
        'AllStatuses': 'Всі',
        'DRAFT': 'Чернетка',
        'READY': 'Готів',
        'STOPPED': 'Неактивний',
        'ACTIVE': 'Активний',
        'LEARNING': 'Навчання'
    },
    recommendationUnit: {
        'UnitStatus': 'Статус юніту',
        'RecommendationUnits': 'Рекомендаційні юніти',
        'Subject': 'Предмет',
        'SubjectRecommendation': 'Предмет рекомендації',
        'SelectSubjectRecommendation': 'Виберіть предмет рекомендації',
        'SelectRecommendationType':'Виберіть рекомендаційний тип',
        'ButtonAddRecommendationSlot': 'Додати рекомендаційний слот',
        'Cells': 'Комірки',
        'Slot': 'Слот',
        'AbTestGroup': 'А/Б тест-група',
        'TotalNumberCells': 'Загальна кількість клітинок',
        'CollaborativeFilteringBasedOnViews':'Колобаративна фільтрація на основі переглядів',
        'CollaborativeFilteringAndDescriptionOfViewed':'Колобаративна фільтрація та опис переглянутих',
        'EmptySettings':'Пусті налаштування',
        'ByFilling':'За заповненням',
        'Empty': 'Порожній',
        'CreateNew': 'Створити новий',
        'PreviouslyUsed':'Раніше використаний',
        'ByClient':'По клієнту',
        'Static':'Статичний',
        'Personal':'Персональний',
        'SelectMetaType':'Виберіть мета тип',
        'MostPopularGoods':'Найпопулярніші товари',
        'NextGoodsToBuy':'Наступний товар до покупки',
        'CrossSell': 'Перехресний продаж',
        'AccessoriesForSelectedGoods': 'Аксесуари до вибраних товарів',
        'NewType': 'Новий тип',
        'AddConfigurationBlock': 'Додати конфігураційний блок',
        'LearningTime': 'Час навчання',
        'Ranker': 'Рейтинг',
        'Order':'Порядок'
    },
    subjectRecommendationType: {
        'PRODUCT': 'Товар',
        'STOCK': 'Акція',
        'SET': 'Набір',
        'ARTICLE': 'Стаття',
    },
    recommendationMetrics: {
        'Group': 'Група',
        'Conversion': 'Конверсія',
        'ClientConversion': 'Конверсія клієнта',
        'DirectConversion': 'Пряма конверсія',
        'DirectClientConversion': 'Пряма конверсія (клієнти)',
        'CTR': 'CTR',
        'ClientCTR': 'Клієнт CTR',
        'AOV': 'AOV',
        'GroupName': 'Ім\'я групи',
        'unitAllTime': 'Юніт за весь час',
        'ABGroup': 'А/Б група',
        'ABGroupsAllTime': 'А/Б групи за весь час',
        'ABGroupsForPeriod': 'А/Б групи за період',
        'TableNoDataMessage': 'Дані відсутні',
        'LeadingClientConversionRate': 'Провідний коефіцієнт конверсії клієнтів',
        'Significance': 'Значення',
        'NotSignificant': 'Не статистично значуще',
        'LeadingDirectClientConversionRate': 'Ведучий коефіцієнт прямої конверсії клієнтів',
        'Uplift': 'Зростання доходу',
        'DirectUplift': 'Пряме зростання доходу',
        'EmptyMetricsForDateRange': 'Порожні показники для діапазону дат'
    },
    tableRecommendationGroup: {
        'NoName': '',
        'RecommendationSlot': 'Рекомендаційний слот',
        'Cells': 'Комірки',
        'Actions': 'Дії',
        'NoSlots': 'Немає слотів',
        'RecommendationType': 'Рекомендаційний тип',
        'NumericSymbol': '№',
        'ConfigurationBlocks': 'Конфігураційні блоки',
        'NoRecommendationType': 'Немає рекомендаційних типів'
    },
    segments: {
        'NewSegment': 'Новий сегмент',
        'TypeSegments': 'Тип сегмента',
        'StandardSegmentDescription': 'Налаштування за умов сегментації',
        'ManualSegmentDescription': 'Налаштування пошуку атрибутів клієнта',
        'GoThroughTutorial': 'Пройти туторіал',
        'GoThroughTutorialDescription': 'Ви навчитеся створювати сегмент'
    },
    comparisonSegments: {
        'Segment': 'Сегмент',
        'Segments': 'Сегменти',
        'Clients': 'Клієнти',
        'Add': 'Додати',
        'Conditions': 'Умови',
        'Comparison': 'Порівняння',
        'TotalClients': 'Всього клієнтів:',
        'ARPU': 'ARPU:',
        'AverageCheck': 'Середній чек:',
        'FrequencyPurchasesYear': 'Частота покупок за рік:',
        'FrequencyPurchases30Days': 'Частота покупок за 30 днів:',
        'ChurnRate': 'Відсоток відтоку:',
        'TotalClientsYear': 'Всього клієнтів за рік:',
        'TotalARPUYear': 'ARPU за рік:',
        'AverageCheckYear': 'Середній чек за рік:',
        'AverageValueSegments': 'Середнє значення сегментів',
        'ValueCurrentSegment': 'Значення даного сегмента',
    },
    conditionSegments: {
        'IncludeToSegment': 'Включити до сегменту',
        'ExcludeFromSegment': 'Виключити із сегмента',
        'Include': 'Увімкнути',
        'Exclude': 'Виключити',
        'Add': 'Додати',
        'SeparateAnd': 'І',
        'SeparateOr': 'Або'
    },
    clientSegment: {
        'PhoneNumber': 'Номер телефону:',
        'Email': 'Email:',
        'LoyalCardNumber': 'Номер картки:',
        'UniqueUserId': 'Унікальний ідентифікатор користувача:',
        'UserName': 'Ім\'я:',
        'LastUpdated': 'Останні зміни:',
        'CommunicationChannels': 'Діяльність інших каналів зв\'язку',
        'MainTabButton': 'Головна',
        'AdditionalTabButton': 'Додаткова',
        'Birthday': 'День народження',
        'Gender': 'Стать',
        'UserId': 'ID користувача',
        'DeviceId': 'ID пристрою',
        'ClientId': 'ID користувача'
    },
    createScenarioSideBar: {
        'TitleNewScenario': 'Новий сценарій',
        'TypeScenario': 'Тип сценарія',
        'EmptyScenarioLabel': 'Пустий сценарій',
        'EmptyScenarioDescription': 'Без попередньо заданих налаштувань',
        'LibraryScenario': 'Бібліотечний сценарій'
    },
    fileDropzone: {
        'UploadCsvFile': 'Завантажте .csv',
        'DropFileHereLabel': 'Перетягніть файл сюди',
        'Bytes': 'байтів',
        'DragAndDrop': 'Перетягніть',
        'YourImage': 'Ваше зображення',
    },
    integrationsTable: {
        'TableColumnEventName': 'Назва події',
        'TableColumnFieldName': 'Ім\'я поля',
        'TableColumnType': 'Тип',
        'TableColumnIsRequired': "Обов'язкове",
        'TableColumnActions': 'Дії',
        'TableColumnIsInternal': 'Є внутрішнім',
        'TableColumnTopic': 'Тема',
    },
    integrations: {
        'UploadOrdersLabel': 'Завантаження замовлень',
        'UploadOrderItemsLabel': 'Завантаження позиції товарів',
        'UploadCustomersLabel': 'Завантаження клієнтів',
        'UploadProductsLabel': 'Завантаження продуктів',
        'ViewSchemaButtonLabel': 'Перегляд схеми',
        'FieldNameLabel': 'Назва поля *',
        'TypeLabel': 'Тип *',
        'RequiredFieldLabel': "Обов'язкове поле",
        'UploadCsvHeaderTitle': 'Завантажте CSV',
        'LeftButtonUploadCsvHeaderTitle': 'Почати завантаження',
        'RightButtonUploadCsvHeaderTitle': 'Назад',
        'CreateDataMappingForEvent': 'Створіть зіставлення даних для події',
        'CsvUploadTuningSectionNotice': '* Переконайтеся, що завантажена таблиця .csv містить імена стовпців у першому рядку',
        'SetupCsvUploadNotice': '** Перші 20 рядків відображаються лише як приклад. Усі дані будуть завантажені',
        'CsvTableDropBoxLabel': 'Перетягніть сюди',
        'UploadCsvProcessTitle': 'Завантаження csv таблиці',
        'ApiFailureUploadCsvProcessTitle': 'Помилка завантаження. Спробуйте ще раз',
        'IngestionProcessLabel': 'Процес завантаження',
        'SuccessfullyUploadMessageFirstPart': 'Успішно отримано',
        'SuccessfullyUploadMessageSecondPart': 'подій із csv файлу',
        'IntegrationsPageTitle': 'Інтеграції',
        'SeeAllEventsButton': 'Переглянути всі події',
        'GoBackToUploadButton': 'Повернутись до завантаження',
        'AddNewEventSidebarTitle': 'Нова подія',
    },
    frequencyPolicies: {
        'NewPolicyTitle': 'Новий поліс',
        'PolicyNameLabel': 'Ім\'я *',
        'PolicyMaximumCommunicationsLabel': 'Максимум комунікацій *',
        'PolicyFrequencyLabel': 'Частота *',
        'FieldName': 'Ім\'я',
        'MaximumCommunications': 'Максимум комунікацій',
        'Frequency': 'Частота',
        'ScenariosWithPolicy': 'Сценарії з полісі',
        'Actions': 'Дії',
        'CommunicationFrequencyTitle': 'Налаштування частоти комунікацій',
    },
    mainPage: {
        'Welcome': 'Ласкаво просимо',
        'WelcomeDesc': 'Вивчіть свою клієнтську базу, завантаживши свої замовлення',
        'WelcomeButtonName': 'Підключити джерело замовлень',
        'MainPageTitle': 'Аналіз вашої клієнтської бази',
        'MainPageDesc': 'Мы пропустим через нашу аналитическую систему, рассчитаем закономерности, уловим ваши текущие проблемы и предложим решения',
        'Circle1Desc': 'Зрозумійте довічну цінність ваших клієнтів',
        'Circle2Title': 'Динаміка клієнтів',
        'Circle2Desc': 'Де ви, клієнти, на шляху до клієнта та як вони переміщаються між ними',
        'Circle3Title': 'Дохід',
        'Circle3Desc': 'Які основні драйвери доходів',
        'MainPageSubTitle': 'Стратегія утримання клієнтів',
        'MainPageSubDesc': 'Аналізуючи ваші дані, ми виявляємо найважливіші проблеми та формуємо стратегію утримання для їх вирішення.',
        'Critical': 'Критичний',
        'Risk': 'Ризик',
        'Opportunity': 'Можливість',
    },
    mainPageDashboard: {
        'Welcome': 'Ваша індивідуальна стратегія утримання',
        'WelcomeDesc': 'Вивчіть свої проблеми з утриманням та почніть взаємодію з клієнтами',
        'RetentionStrategy': 'Стратегія утримання',
        'StartWithLabel': 'Почати',
        'isNotReadySectionMessage': 'Аналізуючи ваші дані, ми виявимо найважливіші проблеми та створимо стратегію зберігання для їх вирішення.',
        'isReadySectionMessage': 'Аналізуючи ваші історичні дані про транзакції, ми виявляємо проблеми із утриманням клієнтів, які значно впливають на ваш прибуток.',
        'Critical': 'Критичний',
        'Risk': 'Ризик',
        'Opportunity': 'Можливість',
        'DropdownSectionCriticalDesc': 'Проблеми, які сильно впливають на продаж. Вимагають вашої негайної уваги.',
        'DropdownSectionRiskDesc': 'Можливі критичні проблеми. Вимагати вашої уваги, щоб не допустити переростання у суттєві негативні тенденції.',
        'DropdownSectionOpportunityDesc': 'Можливості майбутнього зростання продажів.',
        'DropdownCardInterval': 'Щомісяця',
        'DropdownCardStatusNew': 'Новий',
        'DropdownCardStatusScenarioLaunched': 'Сценарій запущено, чекаємо на результати',
        'DropdownCardStatusFirstResults': 'У нас є перші результати',
        'DropdownCardStatusResolved': 'Вирішено',
        'LtvDesc': 'Дохід, що кожен клієнт приносить вам. Прагніть збільшити LTV',
        'LtvCard1': 'Тривалість життя у роках',
        'LtvCard2': 'Частота покупок на рік',
        'RevenueTitle': 'Дохід',
        'RevenueDesc': 'Ваші основні джерела прибутку за останні 30 днів',
        'RevenueCard1': 'Активні клієнти за останні 30 днів',
        'RevenueCard2': 'Частота періоду',
        'CustomerDynamicsTitle': 'Клієнтська база',
        'PopUpCardTitle': 'Опис метрики',
        'CustomerDynamicsElem3': 'Клієнти з разовою покупкою',
        'CustomerDynamicsElem6': 'Нові клієнти',
        'CustomerDynamicsElem8': 'Активовано',
        'CustomerDynamicsElem10': 'Ризик відтоку',
        'CustomerDynamicsElem14': 'Підписки',
        'CustomerDynamicsElem16': 'Втрачені',
        'CustomerDynamicsElemDays': 'днів',
        'CustomerDynamicsElemDaysAgo': 'днів тому',
        'CustomerDynamicsElemDaysBefore': 'дня до цього',
        'CustomerDynamicsElem3Message1': 'Здійснив 1 покупку і здійснив більше',
        'CustomerDynamicsElem3Message2': 'днів тому, але менше',
        'CustomerDynamicsElem6Message1': 'Має 1 покупку та покупка була здійснена менше або дорівнює',
        'CustomerDynamicsElem8Message1': 'Має 2 або більше покупок, і остання покупка була здійснена менш ніж',
        'CustomerDynamicsElem10Message1': 'Має хоча б одну покупку, яка була здійснена більше чи одно',
        'CustomerDynamicsElem10Message2': 'днів і менше',
        'CustomerDynamicsElem14Message1': 'Має хоча б один прямий канал зв\'язку та не здійснює покупок',
        'CustomerDynamicsElem16Message1': 'Здійснив хоча б одну покупку, яка була здійснена більше чи одно',
        'CustomerDynamicsElem1Message1': 'Нові клієнти за останні',
        'CustomerDynamicsElem2Message1': 'Сингл за останні',
        'CustomerDynamicsElem2Message2': 'Нові',
        'CustomerDynamicsElem4Message1': 'Ризик відтоку від одного до останнього',
        'CustomerDynamicsElem4Message2': 'Прямий',
        'CustomerDynamicsElem5Message1': 'Активний із синглу за останні',
        'CustomerDynamicsElem5Message2': 'Нові',
        'CustomerDynamicsElem7Message1': 'Активні з нових протягом останніх',
        'CustomerDynamicsElem7Message2': 'Нові за останні',
        'CustomerDynamicsElem9Message1': 'Ризик відтоку з Активних за останні',
        'CustomerDynamicsElem9Message2': 'Активний',
        'CustomerDynamicsElem11Message1': 'Нові, Поодинокі, Активні від передплатників за останнє',
        'CustomerDynamicsElem11Message2': 'Передплатники',
        'CustomerDynamicsElem12Message1': 'Активний через ризик відтоку протягом останніх',
        'CustomerDynamicsElem12Message2': 'Ризик відтоку',
        'CustomerDynamicsElem13Message1': 'Збиті з пантелику через ризик збивання останніх',
        'CustomerDynamicsElem13Message2': 'Ризик відтоку',
        'CustomerDynamicsElem15Message1': 'Активний через відплив за останні',
        'CustomerDynamicsElem15Message2': 'Відток',
        'CustomerDynamicsElem17Message1': 'Нові передплатники за останні',
    },
    calendar: {
        'AllTimeButton': 'Весь час',
        'ThisYearButton': 'Цього року',
        'ThisMonthButton': 'Цього місяця',
        'ThisWeekButton': 'Цього тижня',
        'ClearButton': 'Очистить',
        'AppleButton': 'Застосувати',
        'Selected': 'Вибрано',
        'Days': 'днів'
    },
    clientDashboardPage: {
        'InformationAboutClientTitle': 'Інформація про клієнта',
        'ConsentsTitle': 'Згода клієнта',
        'ClientKpiTitle': 'Статус клієнта',
        'TableOfEvents': 'Події',
        'FilterDropdown': 'Фільтр',
        'SortDropdown': 'Сортування',
        'PeriodButton': 'Період:',
        'PeriodButtonAllTimeLabel': 'Весь час',
        'ActivityStatusLabel': 'Статус активності',
        'SubscriptionDateLabel': 'Дата передплати',
        'OrderDateLabel': 'Дата замовлення',
        'FirstLabel': 'Перший',
        'LastLabel': 'Останній',
        'Order12MonthFrequencyStatusLabel': 'Статус частоти замовлень за 12 місяців',
        'OrdersCurrentMonthStatusLabel': 'Статус замовлень на поточний місяць',
        'CustomerMainOrderSourceLabel': 'Джерело основного замовлення клієнта',
        'CustomerMainPosLabel': 'Основний POS клієнта',
        'OrderMetricsTitle': 'Метрики замовлень',
        'OverallColumn': 'Загальний',
        'Last12MonthColumn': 'Останні 12 місяців',
        'CurrentPeriodColumn': 'Поточний період',
        'OrderCountRow': 'Кількість замовлень',
        'OrdersSumRow': 'Сума замовлень',
        'AovRow': 'AOV',
        'OrdersProductsQuantityRow': 'Кількість замовленої продукції',
        'CommunicationChannelActive': 'Клієнт переходив через канал принаймні один раз за останні 3 місяці',
        'CommunicationChannelNotActive': 'Повідомлення надіслано на цей канал, але клієнт не перейшов через канал',
        'CommunicationChannelUndefined': 'Статус каналу невідомий (жодних повідомлень на цей канал не було доставлено протягом останніх 12 місяців)',
        'CommunicationChannelFailed': 'Збій доставки повідомлень на цьому каналі',
        'CommunicationChannelNotAvailable': 'Цей атрибут недоступний для клієнта',
        'TooltipExternalEvents': 'Події, які викликані діяльністю клієнтів за межами платформи NeuCurrent. Користувачі платформи також можуть додавати на платформу власні події. Усі інші некатегоризовані події є зовнішніми',
        'TooltipWebsiteActivity': 'Активність клієнтів на сайті',
        'TooltipMobileAppActivity': 'Активність клієнта в додатку',
        'TooltipCommunications': 'Події, що стосуються спілкування з клієнтами',
        'TooltipPlatformEvents': 'Події, які запускаються на платформі'
    },
    iconicDropdown: {
        'Newest': 'Нові',
        'Oldest': 'Старі'
    },
    templatesSetupAside: {
        'MasterAsideTitle': 'Ваші майстер-налаштування',
        'MasterAsideDesc': 'Налаштуйте свій основний шаблон, заповнивши всі поля нижче',
        'ImagesAsideTitle': 'Ваші зображення',
        'ImagesAsideDesc': 'Виберіть, завантажте та видаліть потрібні зображення у папках нижче. Натисніть на зображення, щоб скопіювати URL-адресу в буфер обміну.',
        'AsideImagesFoldersTitle': 'Завантажте власні зображення',
        'SettingsAsideTitle': 'Налаштування шаблону',
        'SettingsAsideDesc': 'Заповніть всі поля нижче для налаштування вашого шаблону',
        'TestAsideTitle': 'Тестувати ваш шаблон',
        'TestAsideDesc': 'Заповніть всі поля нижче та протестуйте ваш шаблон'
    },
    policies: {
        'PoliciesLabel': 'Політика',
        'PoliciesDesc': 'Встановіть частоти зв’язку та вимоги щодо згоди для своїх клієнтів',
        'FrequenciesButtonTitle': 'Частоти',
        'FrequenciesButtonDesc': 'Як часто ви хочете надсилати повідомлення своїм клієнтам?',
        'ConsentsButtonTitle': 'Згоди',
        'ConsentsButtonDesc': 'Встановіть вимоги щодо згоди для своїх клієнтів',
    },
    metricViewPage: {
        'LaunchExperimentButton': 'Запустити експеримент',
        'ProblemsListSectionTitle': 'Проблеми, в яких є ця метрика',
        'PastYearLabel': 'Минулий рік',
        'CurrentYearLabel': 'Поточний рік',
        'DetectedProblemLabel': 'Виявлена проблема',
    }
};
