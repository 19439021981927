import React, { useState } from 'react';
import styles from 'pages/Auth/RequestPasswordRecovery/RequestPasswordRecovery.module.css';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { Logo } from 'shopify/components/Logo/Logo';
import { Title } from 'shopify/components/Title/Title';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { InputField } from 'shopify/modules/InputField/InputField';
import { Button } from 'shopify/components/Button/Button';
import { LeftDirectionArrow } from 'shopify/icons/LeftDirectionArrow';
import { NavLinkWithIcon } from 'shopify/components/NavLinkWithIcon/NavLinkWithIcon';
import { mapValues } from 'lodash';
import Validator from 'validatorjs';
import axios from 'axios';
import { CommonMethods } from 'lib/CommonMethods';
import { useNavigate } from 'react-router-dom';

const passwordRecoveryFormDataInitialState = {
    email: { value: '', error: '' },
};
const passwordRecoveryFormDataValidationRules = {
    email: 'required|email',
};
export const RequestPasswordRecovery = () => {
    const navigate = useNavigate();

    const [passwordRecoveryFormData, setPasswordRecoveryFormData] = useState(passwordRecoveryFormDataInitialState);

    const onChangeInput = event => {
        event.persist();

        setPasswordRecoveryFormData(prev => ({
            ...prev,
            [event.target.name]: {
                ...prev[event.target.name],
                value: event.target.value
            }
        }));
    };

    const onFocusClearErrors = event => {
        event.persist();

        setPasswordRecoveryFormData(prev => ({
            ...prev,
            [event.target.name]: {
                ...prev[event.target.name],
                error: ''
            }
        }));
    };

    const onClickPasswordRecoveryButton = () => {
        const userData = mapValues(passwordRecoveryFormData, input => input.value);

        const validation = new Validator(userData, passwordRecoveryFormDataValidationRules);

        if (validation.fails()) {
            return setPasswordRecoveryFormData(prev => mapValues(prev, (values, key) => ({
                ...values,
                error: validation.errors.first(key) || ''
            })))
        }

        axios
            .request({
                url: CommonMethods.replaceUrlEndpoint('password'),
                method: 'POST',
                config: { headers: { 'Content-Type': 'application/json' } },
                data: {
                    email: passwordRecoveryFormData.email.value
                }
            })
            .then(({ data }) => {
                if (data.username) {
                    navigate(`/success-request-password-recovery?username=${data.username}`);
                }
            })
            .catch((error) => {
                console.error('Failed to fetch retailer info:', error);
            });
    };

    return (
        <RoundLayout>
            <React.Fragment>
                <Logo/>

                <div className={styles.title}>
                    <Title
                        text='Reset your password'
                    />

                    <TextSecondary
                        className={styles.text}
                        text='Enter the email you use to sign in to NeuCurrent to reset your password'
                    />
                </div>

                <div className={styles.form}>
                    <InputField
                        id='email'
                        type='email'
                        label='Email'
                        value={passwordRecoveryFormData.email.value}
                        placeholder='user@gmail.com'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!passwordRecoveryFormData.email.error}
                        error={passwordRecoveryFormData.email.error}
                        required
                        autoFocus
                    />

                    <Button
                        text='Get started'
                        onClick={onClickPasswordRecoveryButton}
                    />
                </div>

                <NavLinkWithIcon
                    icon={<LeftDirectionArrow/>}
                    text='Back to Sign in'
                    route='/sign-in'
                />
            </React.Fragment>
        </RoundLayout>
    )
}