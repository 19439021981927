import React, { useEffect, useState } from 'react';
import styles from 'shopify/structures/Slider/Slider.module.css';
import animations from 'animations/FadeIn.module.css';
import { map } from 'lodash';
import { SliderCard } from 'shopify/modules/SliderCard/SliderCard';
import classNames from 'classnames/bind';
import { StrategiesImage } from 'shopify/svg/SliderCards/StrategiesImage';
import { MetricsImage } from 'shopify/svg/SliderCards/MetricsImage';
import { ScenariosImage } from 'shopify/svg/SliderCards/ScenariosImage';
import { ChartsImage } from 'shopify/svg/SliderCards/ChartsImage';

const cx = classNames.bind([styles, animations]);

const cardData = [
    { id: 1, image: <MetricsImage/>, text: 'Easily track all the necessary statistics and metrics' },
    { id: 2, image: <StrategiesImage/>, text: 'Apply our strategies to boost your results' },
    { id: 3, image: <ScenariosImage/>, text: 'Easily create and customize your own scenarios' },
    { id: 4, image: <ChartsImage/>, text: 'Oversee and analyse data on visual charts' },
];
export const Slider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === cardData.length - 1
                    ? 0
                    : prevIndex + 1
            );
        }, 5000); // Меняем карточку каждые 5 секунды

        return () => clearInterval(interval); // Очищаем интервал при размонтировании компонента
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.cards}>
                {map(cardData, (card, index) => (
                    <div key={card.id} className={cx(styles.card, animations.fadeIn300ms, { [styles.active]: index === currentIndex, [styles.inactive]: index !== currentIndex })}
                    >
                        <SliderCard
                            image={card.image}
                            text={card.text}
                        />
                    </div>
                ))}
            </div>
            <div className={styles.indicators}>
                {map(cardData, (card, index) => (
                    <span key={card.id} className={cx({ [styles.active]: index === currentIndex })}/>
                ))}
            </div>
        </div>
    )
}