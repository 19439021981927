import React from 'react';
import styles from './Title.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const Title = ({ text, className }) => {
    return (
        <h1 className={cx(styles.container, className)}>
            {text}
        </h1>
    )
}
Title.propTypes = {
    text: PropTypes.string.isRequired
}