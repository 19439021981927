import { eq, indexOf, join, pullAt, split, forEach, chain } from 'lodash';

export class NumberUse {

    // This method remove some values from string type number at the beginning
    // For example if values arguments like ['0', '-'] => method remove "zero" and "minus" from string type number at the beginning
    static removeStartValuesFromStringNumber(number, values) {
        const array = split(number, '');

        forEach(values, value => {
            if (array.length > 1 && eq(indexOf(array, value), 0) ) {
                pullAt(array, 0);
            }
        })

        return join(array, '');
    }
    // This method transform string type number to positive value only
    static transformToPositiveNumber(number) {
        return this.removeStartValuesFromStringNumber(number, ['0', '-']);
    }
    // This method returns a number limited by the maximum value
    static maxCountLimitation(number, maxCount) {
        const positiveNumber = this.transformToPositiveNumber(number);

        return positiveNumber > maxCount
            ? maxCount
            : positiveNumber
    }

    static getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    static withoutFloatInNumber(number) {
        return chain(split(number, '.')).join('').value();
    }

    static toLocaleString(number) {
        return number.toLocaleString('en-UK');
    }

    // Example: a = 10, b = 7 return 30
    static percentageDifference(a, b) {
        return (b - a) / b * 100;
    }

    static getNumberOfPages(rowCount, rowsPerPage) {
        return Math.ceil(rowCount / rowsPerPage);
    }
    static zippedFormatNumber(number) {
        // Если число меньше 1000, возвращаем его как есть
        if (number < 1000) {
            return number;
        }
        // Если число между 1000 и 999999, сокращаем до тысяч
        else if (number >= 1000 && number < 1000000) {
            return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        // Если число 1 миллион и больше, сокращаем до миллионов
        else {
            return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
    }
}
