import React from 'react';
import styles from './TextWarning.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const TextWarning = ({ text, className }) => {
    return (
        <p className={cx(styles.container, className)}>
            {text}
        </p>
    )
};

TextWarning.propTypes = {
    text: PropTypes.string
};
TextWarning.defaultProps = {
    text: ''
}