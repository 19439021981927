import React from 'react';
import styles from './Input.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const Input = ({ id, name, type, onChange, placeholder, value, required, warning, autoFocus, onFocus }) => {
    return (
        <input
            id={id}
            type={type}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            autoFocus={autoFocus}
            placeholder={placeholder}
            required={required}
            value={value}
            className={cx(styles.container, { [styles.warning]: warning })}
        />
    )
}

Input.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    warning: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onFocus: PropTypes.func
}
Input.defaultProps = {
    type: 'text',
    placeholder: '',
    value: '',
    required: false,
    warning: false,
    autoFocus: false,
    onFocus: () => {}
}