import React, { useState } from 'react';
import styles from './PricingCalculatorCustomersSetup.module.css';
import PropTypes from 'prop-types';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import { map, toString, toNumber } from 'lodash';
import { Slider } from 'shopify/components/Slider/Slider';
import { NumberUse } from 'lib/NumberUse';
import classNames from 'classnames/bind';
import { ChevronRightIcon } from 'shopify/icons/ChevronRightIcon';

const cx = classNames.bind(styles);

export const PricingCalculatorCustomersSetup = ({ values, selected, onChangeSliderValue }) => {
    const [value, setValue] = useState(selected.sliderValue);
    const onChange = event => {
        setValue(event.target.value)
        onChangeSliderValue(event.target.value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.values}>
                {map(values, (value, index) =>
                    value !== 'custom'
                        ? <div className={cx(styles.value, { 'selected': (index + 1) === selected.sliderValue })} key={value}>
                            <TextPrimary
                                text='Up to'
                                className={styles.text}
                            />
                            <TextPrimary
                                text={toString(NumberUse.zippedFormatNumber(value))}
                                strong
                                className={styles.text}
                            />
                        </div>
                        : <div className={cx(styles.value, { 'selected': (index + 1) === selected.sliderValue })} key={value}>
                            <div className={styles.icon}>
                                <ChevronRightIcon/>
                            </div>
                            <TextPrimary
                                text='700k'
                                strong
                                className={styles.text}
                            />
                        </div>
                )}
            </div>
            <Slider
                value={toNumber(value)}
                onChange={onChange}
                min={1}
                max={values.length}
            />
        </div>
    )
};

PricingCalculatorCustomersSetup.propTypes = {
    values: PropTypes.array.isRequired,
    selected: PropTypes.object.isRequired,
    onChangeSliderValue: PropTypes.func.isRequired
};