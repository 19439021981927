import React from 'react';
import styles from './InputHiddenField.module.css';
import { Label } from 'shopify/components/Label/Label';
import { InputHidden } from 'shopify/components/InputHidden/InputHidden';
import PropTypes from 'prop-types';
import { TextWarning } from 'shopify/components/TextWarning/TextWarning';

export const InputHiddenField = ({ id, name, type, label, required, onChange, placeholder, value, warning, error, onFocus, autoFocus }) => {
    return (
        <div className={styles.container}>
            <Label
                htmlFor={id}
                text={label}
                required={required}
            />
            <InputHidden
                id={id}
                name={name || id}
                type={type}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                required={required}
                value={value}
                warning={warning}
                autoFocus={autoFocus}
            />
            <TextWarning
                text={error}
            />
        </div>
    )
}

InputHiddenField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    warning: PropTypes.bool.isRequired,
    error: PropTypes.string,
    onFocus: PropTypes.func,
    autoFocus: PropTypes.bool
};
InputHiddenField.defaultProps = {
    error: '',
    onFocus: () => {},
    autoFocus: false
};