import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import styles from 'components/MainHeader/MainHeader.module.css';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    dynamicAnalyticsDataSelector,
    dynamicEventNameSelector,
    dynamicScenarioNameSelector,
    dynamicSegmentNameSelector,
    dynamicTemplateNameSelector,
    dynamicTemplateEmailsFolderNameSelector,
    dynamicTemplateEmailsMasterNameSelector,
    dynamicConsentNameSelector
} from 'store/selectors/breadcrumbs.selector';

const templateTypes = {
    email: 'mainHeaderBreadcrumbs:email',
    viber: 'mainHeaderBreadcrumbs:viber',
    sms: 'mainHeaderBreadcrumbs:sms',
    consent: 'mainHeaderBreadcrumbs:consent',
    website: 'mainHeaderBreadcrumbs:website',
    webpushnotify: 'mainHeaderBreadcrumbs:webpushnotify',
    mobpushnotify: 'mainHeaderBreadcrumbs:mobpushnotify'
};

const dynamicTemplateType = translator => {
    return ({ match }) => <span>{translator(templateTypes[match.params.templateType])}</span>
};
const dynamicScenarioName = scenarioName => {
    return () => <span>{scenarioName}</span>
};
const dynamicTemplateName = templateName => {
    return () => <span>{templateName}</span>
};
const dynamicTemplateEmailsFolderName = templateEmailsFolderName => {
    return () => <span>{templateEmailsFolderName}</span>
};
const dynamicTemplateEmailsMasterName = templateEmailsMasterName => {
    return () => <span>{templateEmailsMasterName}</span>
};
const dynamicAnalyticsMetricName = metricName => {
    return () => <span>{metricName}</span>
};
const dynamicSegmentName = segmentName => {
    return () => <span>{segmentName}</span>
};
const dynamicEventName = eventName => {
    return () => <span>{eventName}</span>
};
const dynamicConsentName = consentName => {
    return () => <span>{consentName}</span>
};

const routes = props => ([
    { path: '/', breadcrumb: props.translator('mainHeaderBreadcrumbs:home') },
    { path: '/:metric', breadcrumb: dynamicAnalyticsMetricName(props.analyticsData) },
    { path: '/dashboard', breadcrumb: props.translator('mainHeaderBreadcrumbs:dashboard') },
    { path: '/communications', breadcrumb: props.translator('mainHeaderBreadcrumbs:campaigns') },
    { path: '/communications/communication-frequency-policies', breadcrumb: props.translator('mainHeaderBreadcrumbs:communicationFrequencyPolicies') },
    { path: '/communications/consents', breadcrumb: props.translator('mainHeaderBreadcrumbs:communicationConsents') },
    { path: '/communications/consents/:id', breadcrumb: dynamicConsentName(props.consentName) },
    { path: '/communications/goal', breadcrumb: props.translator('mainHeaderBreadcrumbs:goal') },
    { path: '/communications/goal/all-scenarios', breadcrumb: props.translator('mainHeaderBreadcrumbs:all-scenarios') },
    { path: '/communications/goal/all-scenarios/:id', breadcrumb: dynamicScenarioName(props.scenarioName) },
    { path: '/all-templates', breadcrumb: props.translator('mainHeaderBreadcrumbs:all-templates') },
    { path: '/all-templates/:templateType', breadcrumb: dynamicTemplateType(props.translator) },
    { path: '/all-templates/:templateType/:id', breadcrumb: dynamicTemplateName(props.templateName) },
    { path: '/all-templates/:templateType/folder', breadcrumb: props.translator('mainHeaderBreadcrumbs:folder') },
    { path: '/all-templates/:templateType/master', breadcrumb: props.translator('mainHeaderBreadcrumbs:master') },
    { path: '/all-templates/:templateType/folder/:id', breadcrumb: dynamicTemplateEmailsFolderName(props.templateEmailsFolderName) },
    { path: '/all-templates/:templateType/master/:id', breadcrumb: dynamicTemplateEmailsMasterName(props.templateEmailsMasterName) },
    { path: '/all-templates/:templateType/master-based', breadcrumb: props.translator('mainHeaderBreadcrumbs:masterBased') },
    { path: '/all-templates/:templateType/master-based/:id', breadcrumb: dynamicTemplateName(props.templateName) },
    { path: '/recommendations', breadcrumb: props.translator('mainHeaderBreadcrumbs:recommendations') },
    { path: '/recommendations/:units', breadcrumb: props.translator('mainHeaderBreadcrumbs:units') },
    { path: '/segments', breadcrumb: props.translator('mainHeaderBreadcrumbs:audiences') },
    { path: '/segments/:idSegment', breadcrumb: dynamicSegmentName(props.segmentName) },
    { path: '/segments/:idSegment/:idClient', breadcrumb: props.translator('mainHeaderBreadcrumbs:client') },
    { path: '/integrations', breadcrumb: props.translator('mainHeaderBreadcrumbs:integrations') },
    { path: '/integrations/all-events', breadcrumb: props.translator('mainHeaderBreadcrumbs:allEvents') },
    { path: '/integrations/all-events/:eventId', breadcrumb: dynamicEventName(props.eventName) },
    { path: '/integrations/all-events/:eventId/add-field', breadcrumb: props.translator('mainHeaderBreadcrumbs:addNewField') },
    { path: '/integrations/upload-orders', breadcrumb: props.translator('mainHeaderBreadcrumbs:uploadOrders') },
    { path: '/integrations/upload-order-items', breadcrumb: props.translator('mainHeaderBreadcrumbs:uploadOrderItems') },
    { path: '/integrations/upload-customers', breadcrumb: props.translator('mainHeaderBreadcrumbs:uploadCustomers') },
    { path: '/integrations/upload-products', breadcrumb: props.translator('mainHeaderBreadcrumbs:uploadProducts') },
    { path: '/billing', breadcrumb: props.translator('mainHeaderBreadcrumbs:billing') },
]);

export const Breadcrumbs = () => {
    const { t: translator, i18n: { language } } = useTranslation();

    const { isAuthenticated } = useSelector(store => store.auth);

    const getScenarioName = useMemo(() => dynamicScenarioNameSelector(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language],
    );
    const getTemplateName = useMemo(() => dynamicTemplateNameSelector(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language],
    );
    const getTemplateEmailsFolderName = useMemo(() => dynamicTemplateEmailsFolderNameSelector(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language],
    );
    const getTemplateEmailsMasterName = useMemo(() => dynamicTemplateEmailsMasterNameSelector(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language],
    );
    const getAnalyticsData = useMemo(() => dynamicAnalyticsDataSelector(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language],
    );
    const getSegmentName = useMemo(() => dynamicSegmentNameSelector(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language],
    );
    const getEventName = useMemo(() => dynamicEventNameSelector(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language],
    );

    const getConsentName = useMemo(() => dynamicConsentNameSelector(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language],
    );

    const breadcrumbsProps = {
        translator,
        scenarioName: useSelector(getScenarioName),
        templateName: useSelector(getTemplateName),
        templateEmailsFolderName: useSelector(getTemplateEmailsFolderName),
        templateEmailsMasterName: useSelector(getTemplateEmailsMasterName),
        analyticsData: useSelector(getAnalyticsData),
        segmentName: useSelector(getSegmentName),
        eventName: useSelector(getEventName),
        consentName: useSelector(getConsentName),
    };

    const breadcrumbs = useBreadcrumbs(routes(breadcrumbsProps));

    return (
        isAuthenticated
            ? <div className={styles.breadcrumbs}>
                {map(breadcrumbs, ({ match, breadcrumb }) =>
                    <span key={match.pathname} className={styles.breadcrumbContainer}>
                        <NavLink to={match.pathname} className={styles.breadcrumb}>{breadcrumb}</NavLink>
                    </span>
                )}
            </div>
            : null
    )
};
