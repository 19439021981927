import React from 'react';
import styles from './Label.module.css';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

export const Label = ({ htmlFor, required, text, className }) => {
    return (
        <label htmlFor={htmlFor} className={cx(styles.container, className, { [styles.required]: required })}>
            {text}
        </label>
    )
}
Label.propTypes = {
    htmlFor: PropTypes.string.isRequired,
    required: PropTypes.bool,
    text: PropTypes.string.isRequired,
    className: PropTypes.string
}
Label.defaultProps = {
    required: false,
    className: ''
}