import React, { useEffect, useRef, useState } from 'react';
import styles from 'pages/Auth/Billing/Billing.module.css';
import { Logo } from 'shopify/components/Logo/Logo';
import { Button } from 'shopify/components/Button/Button';
import { OurProductsDropdown } from 'shopify/components/OurProductsDropdown/OurProductsDropdown';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { Title } from 'shopify/components/Title/Title';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import { InputField } from 'shopify/modules/InputField/InputField';
import { PricingCalculator } from 'shopify/structures/PricingCalculator/PricingCalculator';
import { mapValues, map, find, toNumber, round } from 'lodash';
import Validator from 'validatorjs';
import axios from 'axios';
import { CommonMethods } from 'lib/CommonMethods';
import { useToast } from 'hooks/useToast';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const callbackFormDataValidationRules = {
    name: 'required|min:1|max:32',
    email: 'required|email',
    phoneNumber: 'required|regex:/^\\+?[1-9]\\d{1,14}$/',
    companyName: 'required|min:1|max:32',
};
const BillingPage = () => {
    const { addToast } = useToast();

    const { isAuthenticated } = useSelector(store => store.auth);

    const [callbackFormData, setCallbackFormData] = useState({
        name: { value: '', error: '' },
        email: { value: '', error: '' },
        phoneNumber: { value: '', error: '' },
        companyName: { value: '', error: '' },
    });
    // const [isUserWantNewsletters, setIsUserWantNewsletters] = useState(false);
    const [billingInfo, setBillingInfo] = useState([]);
    const formRef = useRef(null);
    const onChangeInput = event => {
        event.persist();

        setCallbackFormData(prev => ({
            ...prev,
            [event.target.name]: {
                ...prev[event.target.name],
                value: event.target.value
            }
        }));
    };
    const onFocusClearErrors = event => {
        event.persist();

        setCallbackFormData(prev => ({
            ...prev,
            [event.target.name]: {
                ...prev[event.target.name],
                error: ''
            }
        }));
    };
    const onClickCallbackButton = () => {
        const userData = mapValues(callbackFormData, input => input.value);

        const validation = new Validator(userData, callbackFormDataValidationRules);

        if (validation.fails()) {
            return setCallbackFormData(prev => mapValues(prev, (values, key) => ({
                ...values,
                error: validation.errors.first(key) || ''
            })))
        }

        axios
            .request({
                url: CommonMethods.replaceUrlEndpoint('get_demo'),
                method: 'POST',
                config: { headers: { 'Content-Type': 'application/json' } },
                data: {
                    name: callbackFormData.name.value,
                    email: callbackFormData.email.value,
                    company_name: callbackFormData.companyName.value,
                    phone: callbackFormData.phoneNumber.value
                }
            })
            .then((response) => {
                if (response.data) {
                    addToast('You have successfully submitted your request', 'success')
                }
            })
            .catch(() => {
                addToast('Something wrong with book a call service', 'warning');
            });
    };
    // const onChangeUserWantNewsletters = () => setIsUserWantNewsletters(prev => !prev);
    const requestBillingInfo = () => {
        axios
            .request({
                url: CommonMethods.replaceUrlEndpoint('billing_info'),
                method: 'GET',
                config: { headers: { 'Content-Type': 'application/json' } },
            })
            .then((response) => {
                if (response.data) {
                    const transformedBillingInfo = map(response.data, (value, key) => {
                        return { key, price: value, selected: false }
                    });

                    const updatedBillingInfo = [...transformedBillingInfo, { key: 'custom', price: 'Custom pricing', selected: false }];

                    const defaultSelectedBillingInfo = map(updatedBillingInfo, (data, index) => {
                        return toNumber(index) === round((updatedBillingInfo.length / 2)) - 1
                            ? { ...data, selected: true, sliderValue: index + 1 }
                            : { ...data, selected: false, sliderValue: index + 1 }
                    });

                    setBillingInfo(defaultSelectedBillingInfo);
                }
            })
            .catch(() => {
                addToast('Something wrong with get billing prices service', 'warning');
            });
    }

    const onClickScrollToForm = () => {
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const onChangeSelectedBillingInfoSlider = sliderValue => {
        setBillingInfo(prev => map(prev, data => {
            return data.sliderValue === toNumber(sliderValue)
                ? { ...data, selected: true }
                : { ...data, selected: false }
        }));
    };

    useEffect(() => {
        requestBillingInfo();
    }, []); //eslint-disable-line

    return (
        <div className={styles.container}>
            {!isAuthenticated &&
                <header className={styles.header}>
                    <Logo/>

                    <nav>
                        <a href='https://www.neucurrent.com/use-cases/all-cases' target='_blank' rel='noreferrer' className={styles.headerLink}>Use cases</a>

                        <OurProductsDropdown/>

                        <Button
                            text='Get a demo'
                            onClick={onClickScrollToForm}
                            className={styles.button}
                        />
                    </nav>
                </header>
            }

            <div className={cx(styles.about, { 'authenticated': isAuthenticated })}>
                <TextSecondary
                    text='Pricing'
                    className={styles.pageDescription}
                />
                <Title
                    text='Explore transparent pricing based on your needs'
                    className={styles.pageTitle}
                />
            </div>

            {billingInfo.length &&
                <PricingCalculator
                    values={map(billingInfo, data => data.key)}
                    selected={find(billingInfo, { selected: true })}
                    onChangeSliderValue={onChangeSelectedBillingInfoSlider}
                    onClickStartButton={onClickScrollToForm}
                />
            }

            <RoundLayout className={styles.form} ref={formRef}>
                <div className={styles.content}>
                    <div className={styles.title}>
                        <Title
                            text='Engage, Convert, Repeat, Retain your customers, longer'
                            className={styles.titleText}
                        />

                        <div className={styles.description}>
                            <TextPrimary
                                text='Schedule a call to discover how NeuCurrent can benefit you'
                            />
                            <TextSecondary
                                text='All fields are required'
                            />
                        </div>
                    </div>

                    <InputField
                        id='name'
                        type='name'
                        label='Name'
                        value={callbackFormData.name.value}
                        placeholder='Jim Wales'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!callbackFormData.name.error}
                        error={callbackFormData.name.error}
                        required
                    />
                    <InputField
                        id='email'
                        type='email'
                        label='Email'
                        value={callbackFormData.email.value}
                        placeholder='user@gmail.com'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!callbackFormData.email.error}
                        error={callbackFormData.email.error}
                        required
                    />
                    <InputField
                        id='phoneNumber'
                        type='number'
                        label='Phone number'
                        value={callbackFormData.phoneNumber.value}
                        placeholder='+1 234 67 67 890'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!callbackFormData.phoneNumber.error}
                        error={callbackFormData.phoneNumber.error}
                        required
                    />
                    <InputField
                        id='companyName'
                        type='text'
                        label='Company name'
                        value={callbackFormData.companyName.value}
                        placeholder=''
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!callbackFormData.companyName.error}
                        error={callbackFormData.companyName.error}
                        required
                    />

                    {/*<NamedCheckbox*/}
                    {/*    text='Sign up for NeuCurrent’s newsletters, product news and targeted advertising. Unsubscribe anytime'*/}
                    {/*    className={styles.namedCheckbox}*/}
                    {/*    onChange={onChangeUserWantNewsletters}*/}
                    {/*    isChecked={isUserWantNewsletters}*/}
                    {/*/>*/}
                    <Button
                        text='Book a call'
                        onClick={onClickCallbackButton}
                    />
                    <TextPrimary
                        text={
                            <>
                                By submitting this form, you agree to{' '}
                                <a target='_blank' href="https://www.iubenda.com/privacy-policy/11363900/legal" className={styles.link} rel="noreferrer">NeuCurrent Privacy Policy</a>{' '}
                                and{' '}
                                <a target='_blank' href="https://www.iubenda.com/terms-and-conditions/11363900" className={styles.link} rel="noreferrer">Terms of Service</a>.
                            </>
                        }
                    />
                </div>
            </RoundLayout>
        </div>
    )
}

export default BillingPage;