import React, { useState } from 'react';
import styles from './SuccessRequestPasswordRecovery.module.css';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { Logo } from 'shopify/components/Logo/Logo';
import { Title } from 'shopify/components/Title/Title';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { Button } from 'shopify/components/Button/Button';
import { Countdown } from 'shopify/modules/Countdown/Countdown';
import { LeftDirectionArrow } from 'shopify/icons/LeftDirectionArrow';
import { NavLinkWithIcon } from 'shopify/components/NavLinkWithIcon/NavLinkWithIcon';
import { SuccessCircleIcon } from 'shopify/icons/SuccessCircleIcon';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { CommonMethods } from 'lib/CommonMethods';
import { useToast } from 'hooks/useToast';

export const SuccessRequestPasswordRecovery = () => {
    const location = useLocation();
    const { addToast } = useToast();

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [resetCountdown, setResetCountdown] = useState(false);

    const urlParams = new URLSearchParams(location.search);
    const username = urlParams.get('username');

    const onCountdownEnd = () => setIsButtonDisabled(false);
    const onResetComplete = () => setResetCountdown(false);

    const onClickResendButton = () => {
        // Устанавливаем новое время окончания таймера и блокируем кнопку
        const now = new Date().getTime();
        const newEndTime = now + 3 * 60 * 1000;
        localStorage.setItem('passwordRecoveryLinkCountdown', newEndTime);
        setIsButtonDisabled(true);
        setResetCountdown(true); // Запускаем таймер заново

        axios
            .request({
                url: CommonMethods.replaceUrlEndpoint('password'),
                method: 'POST',
                config: { headers: { 'Content-Type': 'application/json' } },
                data: {
                    email: username
                }
            })
            .then((response) => {
                if (response.data) {
                    addToast('New password recovery link has been sent to your email')
                }
            })
            .catch(() => {
                addToast('Something wrong with resend new password recovery link service', 'warning');
            });
    };

    return (
        <RoundLayout>
            <React.Fragment>
                <Logo/>

                <div className={styles.circle}>
                    <SuccessCircleIcon/>
                </div>

                <div className={styles.title}>
                    <Title
                        text='Check your email for a code'
                    />

                    <TextSecondary
                        className={styles.text}
                        text={
                            <React.Fragment>
                                We’ve sent an email to{' '}
                                <span>{username}</span>{' '}
                                Follow its instructions to reset your password.
                                Can’t find your code? Check your spam folder
                            </React.Fragment>
                        }
                    />
                </div>

                <Button
                    text='Send link'
                    onClick={onClickResendButton}
                    className={styles.button}
                    disabled={isButtonDisabled}
                />

                <Countdown
                    reset={resetCountdown}
                    onCountdownEnd={onCountdownEnd}
                    onResetComplete={onResetComplete} // Сбрасываем флаг после запуска таймера
                    localeStorageKey='passwordRecoveryLinkCountdown'
                />

                <NavLinkWithIcon
                    icon={<LeftDirectionArrow/>}
                    text='Back to Sign in'
                    route='/sign-in'
                />
            </React.Fragment>
        </RoundLayout>
    )
}