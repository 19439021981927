import React from 'react';
import styles from './Requirements.module.css';
import PropTypes from 'prop-types';
import { SuccessCheckArrowIcon } from 'shopify/icons/SuccessCheckArrowIcon';
import { LineIcon } from 'shopify/icons/LineIcon';
import { isNull } from 'lodash';

export const Requirements = ({ text, done }) => {
    return (
        <div className={styles.container}>
            {isNull(done)
                ? <LineIcon stroke='var(--Slate-200)'/>
                : done
                    ? <SuccessCheckArrowIcon/>
                    : <LineIcon/>
            }
            <p className={styles.text}>
                {text}
            </p>
        </div>
    )
}
Requirements.propTypes = {
    text: PropTypes.string.isRequired,
    done: PropTypes.bool
}
Requirements.defaultProps = {
    done: null
}