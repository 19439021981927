import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ScenarioSetupLayout } from 'components/ScenarioSetup/ScenarioSetupLayout';
import { NcLoader } from 'common/_NcLoader/NcLoader';

const MainPage = lazy(() => import('pages/Main/index'));
const MetricViewPage = lazy(() => import('pages/Main/MetricViewPage/MetricViewPage'));
const ScenariosListPage = lazy(() => import('pages/Communications/ScenariosListPage'));
const FrequencyPoliciesPage = lazy(() => import('pages/Communications/FrequencyPoliciesPage/FrequencyPoliciesPage'));
const ConsentsPage = lazy(() => import('pages/Communications/ConsentsPage/ConsentsPage'));
const ConsentSetupPage = lazy(() => import('pages/Communications/ConsentsPage/ConsentSetupPage'));
const Templates = lazy(() => import('pages/Templates'));
const Recommendations = lazy(() => import('pages/Recommendations'));
const Segments = lazy(() => import('pages/Segments'));
const PageNotFound = lazy(() => import('pages/PageNotFound/PageNotFound'));
const Integrations = lazy(() => import('pages/Integrations'));
const BillingPage = lazy(() => import('pages/Auth/Billing/BillingPage'));

export const MainRoutes = () => {
    return (
        <main>
            <Routes>
                <Route path='/' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <MainPage/>
                    </Suspense>
                }/>
                <Route path='/:metric/*' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <MetricViewPage/>
                    </Suspense>
                }/>

                <Route path='/communications/goal/:goal' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <ScenariosListPage/>
                    </Suspense>
                }/>

                <Route path='/communications/communication-frequency-policies' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <FrequencyPoliciesPage/>
                    </Suspense>
                }/>

                <Route path='/communications/consents' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <ConsentsPage/>
                    </Suspense>
                }/>

                <Route path='/communications/consents/:id' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <ConsentSetupPage/>
                    </Suspense>
                }/>

                <Route path='/communications/goal/:goal/:id/*' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <ScenarioSetupLayout/>
                    </Suspense>
                }/>

                <Route path='/all-templates/*' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <Templates/>
                    </Suspense>
                }/>

                <Route path="/recommendations/:units/*" element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <Recommendations/>
                    </Suspense>
                }/>

                <Route path='/segments/*' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <Segments/>
                    </Suspense>
                }/>

                <Route path='/integrations/*' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <Integrations/>
                    </Suspense>
                }/>

                <Route path='/billing' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <BillingPage/>
                    </Suspense>
                }/>

                <Route path='/recommendations' element={ <Navigate replace to='/recommendations/units-all'/> }/>
                <Route path='/all-templates' element={ <Navigate replace to='/all-templates/email'/> }/>
                <Route path='/communications' element={ <Navigate replace to='/communications/goal/all-scenarios'/> }/>
                <Route path='/communications/goal' element={ <Navigate replace to='/communications/goal/all-scenarios'/> }/>

                <Route path='*' element={
                    <Suspense fallback={ <NcLoader minHeight='calc(100vh - 65px)'/> }>
                        <PageNotFound/>
                    </Suspense>
                }/>
            </Routes>
        </main>
    )
};
