import React from 'react';
import styles from './AttentionText.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const AttentionText = ({ icon, text, strong }) => {
    return (
        <div className={cx(styles.container, { [styles.strong]: strong })}>
            <div className={styles.icon}>
                {icon}
            </div>
            <p>{text}</p>
        </div>
    )
};

AttentionText.propTypes = {
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    strong: PropTypes.bool
};
AttentionText.defaultProps = {
    strong: false
}