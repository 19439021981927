import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SignIn } from 'pages/Auth/SignIn/SignIn';
import { SignUp } from 'pages/Auth/SignUp/SignUp';
import { Verification } from 'pages/Auth/Verification/Verification';
import { Processing } from 'pages/Auth/Processing/Processing';
import { RequestPasswordRecovery } from 'pages/Auth/RequestPasswordRecovery/RequestPasswordRecovery';
import {
    SuccessRequestPasswordRecovery
} from 'pages/Auth/SuccessRequestPasswordRecovery/SuccessRequestPasswordRecovery';
import { PasswordRecovery } from 'pages/Auth/PasswordRecovery/PasswordRecovery';
import BillingPage from 'pages/Auth/Billing/BillingPage';

export const Auth = () => {
    return (
        <main>
            <Routes>
                <Route path='/' element={ <SignIn/> }/>
                <Route path='/sign-up' element={ <SignUp/> }/>
                <Route path='/verification' element={ <Verification/> }/>
                <Route path='/processing' element={ <Processing/> }/>
                <Route path='/request-password-recovery' element={ <RequestPasswordRecovery/> }/>
                <Route path='/success-request-password-recovery' element={ <SuccessRequestPasswordRecovery/> }/>
                <Route path='/password-recovery' element={ <PasswordRecovery/> }/>
                <Route path='/billing' element={ <BillingPage/> }/>
                <Route path="*" element={ <SignIn/> }/>
            </Routes>
        </main>
    )
};
