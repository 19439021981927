import React, { useState } from 'react';
import styles from './PasswordRecovery.module.css';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { Logo } from 'shopify/components/Logo/Logo';
import { Title } from 'shopify/components/Title/Title';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { InputHiddenField } from 'shopify/modules/InputHiddenField/InputHiddenField';
import { StringStrengthMeter } from 'shopify/modules/StringStrengthMeter/StringStrengthMeter';
import { Requirements } from 'shopify/modules/Requirements/Requirements';
import { Button } from 'shopify/components/Button/Button';
import { StringUse } from 'lib/StringUse';
import { mapValues } from 'lodash';
import Validator from 'validatorjs';
import axios from 'axios';
import { CommonMethods } from 'lib/CommonMethods';
import { useLocation, useNavigate } from 'react-router-dom';

const passwordRecoveryFormDataInitialState = {
    password: { value: '', error: '' },
    confirmPassword: { value: '', error: '' }
};
const passwordRecoveryFormDataValidationRules = {
    password: 'required|min:8|max:24|regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])/',
    confirmPassword: 'required|min:8|max:24|same:password'
};
export const PasswordRecovery = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [passwordRecoveryFormData, setPasswordRecoveryFormData] = useState(passwordRecoveryFormDataInitialState);
    const [strength, setStrength] = useState('default');

    const urlParams = new URLSearchParams(location.search);
    const username = urlParams.get('username');

    const onChangeInput = event => {
        event.persist();

        setPasswordRecoveryFormData(prev => ({
            ...prev,
            [event.target.name]: {
                ...prev[event.target.name],
                value: event.target.value
            }
        }));

        if (event.target.name === 'password') {
            setStrength(StringUse.calculatePasswordStrength(event.target.value));
        }
    };

    const onClickPasswordRecoveryButton = () => {
        const userData = mapValues(passwordRecoveryFormData, input => input.value);

        const validation = new Validator(userData, passwordRecoveryFormDataValidationRules);

        if (validation.fails()) {
            return setPasswordRecoveryFormData(prev => mapValues(prev, (values, key) => ({
                ...values,
                error: validation.errors.first(key) || ''
            })))
        };

        axios.request({
            url: CommonMethods.replaceUrlEndpoint('password'),
            method: 'PUT',
            config: { headers: { 'Content-Type': 'application/json' } },
            data: {
                reset_token: urlParams.get('reset_token'),
                password: passwordRecoveryFormData.password.value
            }
        })
            .then(({ data }) => {
                if (data.username) {
                    navigate('/');
                }
            })
            .catch((error) => {
                console.error('Failed to fetch retailer info:', error);
            });
    }

    const onFocusClearErrors = event => {
        event.persist();

        setPasswordRecoveryFormData(prev => ({
            ...prev,
            [event.target.name]: {
                ...prev[event.target.name],
                error: ''
            }
        }));
    };

    return (
        <RoundLayout>
            <React.Fragment>
                <Logo/>

                <div className={styles.title}>
                    <Title
                        text='Set a new password'
                    />

                    <TextSecondary
                        className={styles.text}
                        text={
                            <React.Fragment>
                                Enter a new password below to change your password. Account:{' '}
                                <span>{username}</span>
                            </React.Fragment>
                        }
                    />
                </div>

                <div className={styles.form}>
                    <InputHiddenField
                        id='password'
                        type='password'
                        label='Password'
                        value={passwordRecoveryFormData.password.value}
                        placeholder='******'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!passwordRecoveryFormData.password.error}
                        error={passwordRecoveryFormData.password.error}
                        required
                        autoFocus
                    />
                    <StringStrengthMeter
                        strength={strength}
                    />
                    <div className={styles.requirements}>
                        <Requirements
                            text="Lowercase and Uppercase letters"
                            done={/^(?=.*[a-z])(?=.*[A-Z])/.test(passwordRecoveryFormData.password.value)}
                        />
                        <Requirements
                            text="Numbers"
                            done={/^(?=.*[0-9])/.test(passwordRecoveryFormData.password.value)}
                        />
                        <Requirements
                            text="Special characters"
                            done={/^(?=.*[!@#%^&*])/.test(passwordRecoveryFormData.password.value)}
                        />
                    </div>
                    <InputHiddenField
                        id='confirmPassword'
                        type='password'
                        label='Confirm password'
                        value={passwordRecoveryFormData.confirmPassword.value}
                        placeholder='******'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!passwordRecoveryFormData.confirmPassword.error}
                        error={passwordRecoveryFormData.confirmPassword.error}
                        required
                    />

                    <Button
                        text='Set a new password'
                        onClick={onClickPasswordRecoveryButton}
                    />
                </div>
            </React.Fragment>
        </RoundLayout>
    )
}