import React, { useEffect, useRef, useState } from 'react';
import { map, forEach, slice, toUpper, join } from 'lodash';
import styles from './CodeVerification.module.css';
import { InputSingleValue } from 'shopify/components/InputSingleValue/InputSingleValue';
import { LineIcon } from 'shopify/icons/LineIcon';
import { TextWarning } from 'shopify/components/TextWarning/TextWarning';
export const CodeVerification = ({ callback, error, clearCodeVerificationErrorCallback }) => {
    const [code, setCode] = useState('');

    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const onChangeInput = index => {
        return event => {
            const input = event.target;
            const previousInput = inputRefs[index - 1];
            const nextInput = inputRefs[index + 1];

            // Update code state with single digit
            const newCode = [...code];
            // Convert lowercase letters to uppercase
            if (/^[a-z]+$/.test(input.value)) {
                const uc = toUpper(input.value);
                newCode[index] = uc;
                inputRefs[index].current.value = uc;
            } else {
                newCode[index] = input.value;
            }
            setCode(join(newCode,''));

            input.select();

            if (input.value === '') {
                // If the value is deleted, select previous input, if exists
                if (previousInput) {
                    previousInput.current.focus();
                }
            } else if (nextInput) {
                // Select next input on entry, if exists
                nextInput.current.select();
            }
        }
    };

    const onFocus = event => {
        if (error) {
            clearCodeVerificationErrorCallback();
        }
        event.target.select();
    };

    const onKeyDown = index => {
        return event => {
            const input = event.target;
            const previousInput = inputRefs[index - 1];

            if ((event.keyCode === 8 || event.keyCode === 46) && input.value === '') {
                event.preventDefault();

                setCode((prev) => slice(prev,0, index) + slice(prev,index + 1));

                if (previousInput) {
                    previousInput.current.focus();
                }
            }
        }
    };

    const handlePaste = event => {
        const pastedCode = event.clipboardData.getData('text');

        if (pastedCode.length === 6) {
            setCode(pastedCode);

            forEach(inputRefs,(inputRef, index) => {
                inputRef.current.value = pastedCode.charAt(index);
            });
        }
    };

    // Call our callback when code = 6 chars
    useEffect(() => {
        if (code.length === 6) {
            callback(code);
        }
    }, [code]); //eslint-disable-line

    return (
        <div className={styles.container}>
            <div className={styles.code}>
                {map([0, 1, 2, 3, 4, 5], (index) => (
                    <React.Fragment key={'id' + index}>
                        <InputSingleValue
                            id={'id' + index}
                            ref={inputRefs[index]}
                            type='text'
                            onChange={onChangeInput(index)}
                            onKeyDown={onKeyDown(index)}
                            onFocus={onFocus}
                            onPaste={handlePaste}
                            autoFocus={index === 0}
                            warning={error}
                            required
                        />
                        {index === 2 &&
                            <LineIcon/>
                        }
                    </React.Fragment>
                ))}
            </div>

            <TextWarning
                text={error?.message ?? ''}
                className={styles.warning}
            />
        </div>
    )
}