import React from 'react';
import styles from './Button.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const Button = ({ text, onClick, disabled, className }) => {
    return (
        <button
            className={cx(styles.container, className, { [styles.disabled]: disabled })}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </button>
    )
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string
}
Button.defaultProps = {
    disabled: false,
    className: ''
}