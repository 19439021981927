import axios from 'axios';
import Cookies from 'js-cookie';
import { CommonMethods } from 'lib/CommonMethods';
import {
    LOGIN_USER,
    LOGOUT_USER,
    SET_SIGN_UP,
    FETCH_SIGN_UP
} from 'store/types';
import { apiRequestAction } from 'store/actions/api.action';

export const login = values => dispatch => {
    return axios
        .request({
            url: CommonMethods.replaceUrlEndpoint('login'),
            method: 'POST',
            config: { headers: { 'Content-Type': 'application/json' } },
            data: values
        })
        .then(({ data }) => {
            console.log('[Auth Server]: Login response:', 'Authorized!');

            if (data.token) {
                Cookies.set('auth', JSON.stringify({
                    username: values.username,
                    token: data.token,
                    tokenExpireDate: data.expiration_datetime,
                    sandboxMode: data.sandbox_mode,
                    retailerName: data.retailer_name,
                    retailerCurrency: data.retailer_currency,
                    userGivenName: data.user_given_name
                }), CommonMethods.tokenSecuritySettings());

                dispatch({
                    type: LOGIN_USER,
                    payload: {
                        data: {
                            username: values.username,
                            token: data.token,
                            tokenExpireDate: data.expiration_datetime,
                            sandboxMode: data.sandbox_mode,
                            retailerName: data.retailer_name,
                            retailerCurrency: data.retailer_currency,
                            userGivenName: data.user_given_name
                        }
                    }
                });
            }
        })
}
export const makeAuth = (data = {}) => dispatch => {
    dispatch({
        type: LOGIN_USER,
        payload: {
            data: {
                username: data.username,
                token: data.token,
                tokenExpireDate: data.tokenExpireDate,
                sandboxMode: data.sandboxMode,
                retailerName: data.retailerName,
                retailerCurrency: data.retailerCurrency,
                userGivenName: data.userGivenName
            }
        },
    })
};
export const logout = () => dispatch => {
    window.location.protocol && window.location.protocol === 'https:'
        ? Cookies.remove('auth', { domain: '.neucurrent.com' })
        : Cookies.remove('auth')

    dispatch({
        type: LOGOUT_USER,
        payload: {
            defaultUsernameStore: { username: null, token: null, tokenExpireDate: null },
            defaultExperimentsStore: { list: [], metrics: [] },
            defaultScenariosStore: { list: [], metrics: [] },
            defaultCommunicationsStore: { list: [] },
            defaultConsentCategoriesStore: { list: [] },
            defaultTemplatesStore: { list: {
                    SMS: [],
                    Viber: [],
                    Email: [],
                    PersonalPage: [],
                    MobPushNotify: [],
                    WebPushNotify: [],
                } },
            defaultImagesStore: { list: [] }
        }
    })

    return Promise.resolve();
};

const setSignUp = data => ({
    type: SET_SIGN_UP,
    payload: { data }
})
export const getSignUp = (data, onAfterSuccess, onFailureSignUp) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('shopify_sign_up'),
        method: 'POST',
        data,
        onAfterSuccess,
        onSuccess: setSignUp,
        onFailure: onFailureSignUp,
        label: FETCH_SIGN_UP
    })
);
