import React from 'react';
import styles from './TextLink.module.css';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

export const TextLink = ({ text, link }) => {
    return (
        <NavLink
            to={link}
            className={styles.container}
        >
            {text}
        </NavLink>
    )
}
TextLink.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
}