import React from 'react';
import styles from './PricingCalculatorFooter.module.css';
import PropTypes from 'prop-types';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import { Button } from 'shopify/components/Button/Button';
import { AttentionText } from 'shopify/modules/AttentionText/AttentionText';
import { CheckGreen } from 'shopify/icons/CheckGreen';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const PricingCalculatorFooter = ({ selected, onClickStartButton }) => {
    return (
        <RoundLayout className={cx(styles.container, { 'custom': selected.key === 'custom' })}>
            <div className={styles.content}>
                <div className={styles.column}>
                    <TextSecondary
                        text={selected.key === 'custom'
                            ? 'We’ve got for you'
                            : 'Total price'
                        }
                        className={cx({ 'customPricingTextColor': selected.key === 'custom' })}
                    />
                    <TextPrimary
                        text={selected.key === 'custom'
                            ? selected.price
                            : `$${selected.price} / mo`
                        }
                        className={styles.price}
                        strong
                    />
                </div>

                <div className={styles.row}>
                    <AttentionText
                        icon={<CheckGreen/>}
                        text='2 weeks Free trial included'
                        strong
                    />
                    <Button
                        text='Start now'
                        onClick={onClickStartButton}
                        className={styles.button}
                    />
                </div>
            </div>
        </RoundLayout>
    )
};

PricingCalculatorFooter.propTypes = {
    selected: PropTypes.object.isRequired,
    onClickStartButton: PropTypes.func.isRequired
};