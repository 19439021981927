import React from 'react';
import styles from './PricingCalculator.module.css';
import PropTypes from 'prop-types';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { PricingCalculatorHeader } from 'shopify/modules/PricingCalculatorHeader/PricingCalculator';
import { PricingCalculatorFooter } from 'shopify/modules/PricingCalculatorFooter/PricingCalculatorFooter';
import { PricingCalculatorServices } from 'shopify/modules/PricingCalculatorServices/PricingCalculatorServices';
import {
    PricingCalculatorCustomersSetup
} from 'shopify/modules/PricingCalculatorCustomersSetup/PricingCalculatorCustomersSetup';

export const PricingCalculator = ({ values, selected, onChangeSliderValue, onClickStartButton }) => {
    return (
        <RoundLayout className={styles.container}>
            <div className={styles.content}>
                <PricingCalculatorHeader
                    selected={selected}
                />
                <PricingCalculatorCustomersSetup
                    values={values}
                    selected={selected}
                    onChangeSliderValue={onChangeSliderValue}
                />
                {/*<PricingCalculatorCommunications/>*/}
                <PricingCalculatorServices/>
                <PricingCalculatorFooter
                    selected={selected}
                    onClickStartButton={onClickStartButton}
                />
            </div>
        </RoundLayout>
    )
};

PricingCalculator.propTypes = {
    values: PropTypes.array.isRequired,
    selected: PropTypes.object.isRequired,
    onChangeSliderValue: PropTypes.func.isRequired,
    onClickStartButton: PropTypes.func.isRequired
};