import React from 'react';
import styles from './NavLinkWithIcon.module.css';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const NavLinkWithIcon = ({ icon, text, route, reverse, className }) => {
    return (
        <NavLink to={route} className={cx(styles.container, className, { [styles.reverse]: reverse })}>
            {icon}
            {text}
        </NavLink>
    )
};

NavLinkWithIcon.propTypes = {
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    reverse: PropTypes.bool,
    classNames: PropTypes.string
};
NavLinkWithIcon.defaultProps = {
    reverse: false,
    className: ''
}