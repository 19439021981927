import React from 'react';
import styles from './TextSecondary.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
export const TextSecondary = ({ text, className }) => {
    return (
        <p className={cx(styles.container, className)}>
            {text}
        </p>
    )
}
TextSecondary.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    className: PropTypes.string
}
TextSecondary.defaultProps = {
    className: ''
}