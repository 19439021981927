import React from 'react';
import styles from './StringStrengthMeter.module.css';
import PropTypes from 'prop-types';
import { StringStrengthLine } from 'shopify/components/StringStrengthLine/StringStrengthLine';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import { capitalize } from 'lodash';

export const StringStrengthMeter = ({ strength }) => {
    return (
        <div className={styles.container}>
            <div className={styles.labels}>
                <TextPrimary
                    text='Password strength'
                />
                {strength !== 'default' &&
                    <TextPrimary
                        text={capitalize(strength)}
                        strong
                    />
                }
            </div>
            <StringStrengthLine
                strength={strength}
            />
        </div>
    )
}

StringStrengthMeter.propTypes = {
    strength: PropTypes.string
}
StringStrengthMeter.defaultProps = {
    strength: 'default'
}