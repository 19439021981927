import { has } from 'lodash';
import { apiEndType, apiStartType } from 'store/actions/api.action';
import {
    FETCH_SCENARIO_METRIC,
    FETCH_SCENARIO_STOP,
    FETCH_CURRENT_SCENARIO_STOP,
    FETCH_SCENARIO_START,
    FETCH_CURRENT_SCENARIO_START,
    FETCH_SCENARIOS_LIST,
    FETCH_SCENARIOS_METRICS,
    FETCH_SCENARIO_COPY,
    FETCH_SCENARIO_DELETE,
    FETCH_SCENARIOS_SEARCHED,
    FETCH_SCENARIOS_FILTERED,
    FETCH_SCENARIO_CREATE,
    FETCH_SCENARIO_UPDATE,
    FETCH_SILENT_SCENARIO_UPDATE,
    FETCH_SCENARIO_NAME_UPDATE,
    FETCH_SCENARIO,
    FETCH_COMMUNICATION_LIST,
    FETCH_COMMUNICATION_PROVIDER_LIST,
    FETCH_CONSENT_CATEGORIES_LIST,
    FETCH_TEMPLATES_LIST,
    FETCH_TEMPLATES_SEARCHED,
    FETCH_TEMPLATES_FILTERED,
    FETCH_SCENARIO_TEMPLATE_METRICS,
    FETCH_TEMPLATE_UPDATE,
    FETCH_TEMPLATE,
    FETCH_TEMPLATE_DELETE,
    FETCH_METRICS_TEMPLATE,
    FETCH_COPY_TEMPLATE,
    FETCH_TEMPLATE_CREATE,
    FETCH_CONSENT_TEMPLATE,
    FETCH_UPLOADED_IMAGES,
    FETCH_RECOMMENDATION_UNIT_METRICS,
    FETCH_RECOMMENDATION_UNIT_LIST,
    FETCH_CREATE_RECOMMENDATION_UNIT,
    FETCH_DELETE_RECOMMENDATION_UNIT,
    FETCH_ADD_AB_GROUP_RECOMMENDATION_UNIT,
    FETCH_DELETE_AB_GROUP_RECOMMENDATION_UNIT,
    FETCH_UPDATE_RECOMMENDATION_UNIT,
    FETCH_UPDATE_AB_GROUP_RECOMMENDATION_UNIT,
    FETCH_CREATE_SEGMENT,
    FETCH_SEGMENT,
    FETCH_SEGMENTS_LIST,
    FETCH_UPDATE_SEGMENT,
    FETCH_SEGMENT_CLIENTS_LIST,
    FETCH_SEGMENT_CLIENT,
    FETCH_SEGMENT_CLIENT_EVENT,
    FETCH_SEGMENT_EVENTS_CLIENT_LIST,
    FETCH_PROBLEMS,
    FETCH_LIBRARY_SCENARIOS_LIST,
    FETCH_ALL_INTEGRATION_EVENTS,
    FETCH_CREATE_INTEGRATION_EVENT,
    FETCH_DELETE_INTEGRATION_EVENT,
    FETCH_SEGMENTS_TEMPLATE_LIST,
    FETCH_UPDATE_EVENT_SCHEMA_FIELDS,
    FETCH_START_UPLOAD_CSV_TABLE,
    FETCH_GET_UPLOAD_CSV_TABLE_HISTORY,
    FETCH_TEMPLATE_CLIENTS_LIST,
    FETCH_TEST_TEMPLATE,
    FETCH_FREQUENCY_POLICIES_LIST,
    FETCH_UPDATING_FREQUENCY_POLICIES,
    FETCH_IMAGES_FOLDERS_LIST,
    FETCH_CREATE_IMAGES_FOLDER,
    FETCH_DELETE_IMAGE_FOLDER,
    FETCH_DELETE_IMAGE,
    FETCH_UPDATE_FOLDER_NAME,
    FETCH_MASTER_CREATE,
    FETCH_MASTERS_LIST,
    FETCH_METRICS_VIEW_DATA,
    FETCH_METRICS_MAIN_PAGE_DATA,
    FETCH_GET_CONFIG,
    FETCH_DROPDOWN_FILTERS,
    FETCH_RECOMMENDATION_PRODUCT,
    FETCH_UPDATE_CLIENT_CONSENTS,
    FETCH_SILENT_UPDATE_SEGMENTS_LIST,
    FETCH_DELETE_CONSENT_CATEGORY,
    FETCH_CREATE_CONSENT_CATEGORY,
    FETCH_UPDATE_CONSENT_CATEGORY,
    FETCH_CURRENT_PROBLEM,
    API_REQUEST_FAIL_WITH_STATUS,
    CLEAR_API_REQUEST_FAIL_WITH_STATUS,
    FETCH_SIGN_UP
} from 'store/types';

const recommendationPayloads = {
    FETCH_RECOMMENDATION_UNIT_LIST,
    FETCH_CREATE_RECOMMENDATION_UNIT,
    FETCH_DELETE_RECOMMENDATION_UNIT,
    FETCH_ADD_AB_GROUP_RECOMMENDATION_UNIT,
    FETCH_DELETE_AB_GROUP_RECOMMENDATION_UNIT,
    FETCH_UPDATE_RECOMMENDATION_UNIT,
    FETCH_UPDATE_AB_GROUP_RECOMMENDATION_UNIT,
    FETCH_RECOMMENDATION_PRODUCT
};
const recommendationMetricsPayloads = {
    FETCH_RECOMMENDATION_UNIT_METRICS,
}
const scenarioListPayloads = {
    FETCH_SCENARIOS_LIST,
    FETCH_SCENARIO,
    FETCH_SCENARIO_COPY,
    FETCH_SCENARIO_DELETE,
    FETCH_SCENARIO_CREATE,
    FETCH_SCENARIO_UPDATE,
    FETCH_SILENT_SCENARIO_UPDATE,
    FETCH_SCENARIO_NAME_UPDATE,
    FETCH_SCENARIOS_SEARCHED,
    FETCH_SCENARIOS_FILTERED,
};
const scenarioLibraryListPayloads = {
    FETCH_LIBRARY_SCENARIOS_LIST
};
const scenarioMetricsPayloads = {
    FETCH_SCENARIO_METRIC,
    FETCH_SCENARIOS_METRICS
};
const scenarioChangeStatusPayloads = {
    FETCH_SCENARIO_START,
    FETCH_CURRENT_SCENARIO_START,
    FETCH_SCENARIO_STOP,
    FETCH_CURRENT_SCENARIO_STOP
};
const scenarioTemplateMetricsPayloads = {
    FETCH_SCENARIO_TEMPLATE_METRICS
};
const templatePayloads = {
    FETCH_TEMPLATE_DELETE,
    FETCH_TEMPLATES_LIST,
    FETCH_TEMPLATES_SEARCHED,
    FETCH_TEMPLATES_FILTERED,
    FETCH_METRICS_TEMPLATE,
    FETCH_COPY_TEMPLATE,
    FETCH_TEMPLATE_CREATE,
    FETCH_CONSENT_TEMPLATE,
    FETCH_TEMPLATE_CLIENTS_LIST,
    FETCH_TEST_TEMPLATE
};
const emailMastersPayloads = {
    FETCH_MASTERS_LIST,
    FETCH_MASTER_CREATE,
}
const updatingTemplatePayloads = {
    FETCH_TEMPLATE_UPDATE,
};
const getTemplatePayloads = {
    FETCH_TEMPLATE,
};
const communicationsListPayloads = {
    FETCH_COMMUNICATION_LIST,
    FETCH_COMMUNICATION_PROVIDER_LIST
};
const consentCategoriesListPayloads = {
    FETCH_CONSENT_CATEGORIES_LIST,
    FETCH_DELETE_CONSENT_CATEGORY,
    FETCH_CREATE_CONSENT_CATEGORY,
    FETCH_UPDATE_CONSENT_CATEGORY
};
const imageFoldersPayloads = {
    FETCH_IMAGES_FOLDERS_LIST,
    FETCH_CREATE_IMAGES_FOLDER,
    FETCH_DELETE_IMAGE_FOLDER,
    FETCH_UPDATE_FOLDER_NAME
};
const imagesPayloads = {
    FETCH_UPLOADED_IMAGES,
    FETCH_DELETE_IMAGE
}
const segmentsListPayloads = {
    FETCH_SEGMENTS_LIST,
    FETCH_SILENT_UPDATE_SEGMENTS_LIST,
    FETCH_SEGMENT_CLIENTS_LIST,
    FETCH_SEGMENT_EVENTS_CLIENT_LIST,
    FETCH_DROPDOWN_FILTERS
};
const segmentsListSilentUpdatePayloads = {
    FETCH_SILENT_UPDATE_SEGMENTS_LIST,
}
const segmentPayloads = {
    FETCH_CREATE_SEGMENT,
    FETCH_UPDATE_SEGMENT,
    FETCH_SEGMENT,
    FETCH_SEGMENT_CLIENT,
    FETCH_SEGMENTS_TEMPLATE_LIST,
};
const segmentClientEventPayloads = {
    FETCH_SEGMENT_CLIENT_EVENT
}
const segmentClientConsentPayloads = {
    FETCH_UPDATE_CLIENT_CONSENTS
}
const analyticsPayloads = {
    FETCH_PROBLEMS,
    FETCH_METRICS_VIEW_DATA,
    FETCH_METRICS_MAIN_PAGE_DATA,
    FETCH_CURRENT_PROBLEM
};

const integrationsPayloads = {
    FETCH_ALL_INTEGRATION_EVENTS,
    FETCH_CREATE_INTEGRATION_EVENT,
    FETCH_DELETE_INTEGRATION_EVENT,
    FETCH_START_UPLOAD_CSV_TABLE,
    FETCH_GET_UPLOAD_CSV_TABLE_HISTORY,
    FETCH_GET_CONFIG
};
const integrationsEventPayloads = {
    FETCH_CREATE_INTEGRATION_EVENT,
    FETCH_DELETE_INTEGRATION_EVENT,
    FETCH_UPDATE_EVENT_SCHEMA_FIELDS
};

const frequencyPoliciesPayloads = {
    FETCH_FREQUENCY_POLICIES_LIST,
    FETCH_UPDATING_FREQUENCY_POLICIES
};
const authPayloads = {
    FETCH_SIGN_UP
}

const initialState = {
    isLoadingCommunicationsList: false,
    isLoadingConsentCategoriesList: false,
    isLoadingScenariosList: false,
    isLoadingLibraryScenariosList: false,
    isLoadingScenariosMetrics: false,
    isLoadingScenarioTemplateMetrics: false,
    isLoadingChangeScenarioStatus: false,
    isLoadingTemplates: false,
    isLoadingTemplate: false,
    isLoadingUpdatingTemplate: false,
    isLoadingEmailMasters: false,
    isLoadingImageFolders: false,
    isLoadingImages: false,
    isLoadingRecommendationMetrics: false,
    isLoadingRecommendation: false,
    isLoadingSegmentsList: false,
    isLoadingSegmentsListSilentUpdate: false,
    isLoadingSegment: false,
    isLoadingClientEventSegment: false,
    isLoadingClientConsents: false,
    isLoadingAnalytics: false,
    isLoadingIntegration: false,
    isLoadingIntegrationEvent: false,
    isLoadingFrequencyPolicies: false,
    isLoadingAuth: false,
    catchedApiFailWithStatusCode: null,
};
export default function api(store = initialState, { type, payload }) {
    switch (type) {
        case apiStartType(payload):
            // COMMUNICATIONS
            if (has(communicationsListPayloads, payload)) return { ...store, isLoadingCommunicationsList: true };
            // CONSENT CATEGORIES
            if (has(consentCategoriesListPayloads, payload)) return { ...store, isLoadingConsentCategoriesList: true };
            // SCENARIOS
            if (has(scenarioListPayloads, payload)) return { ...store, isLoadingScenariosList: true };
            if (has(scenarioLibraryListPayloads, payload)) return { ...store, isLoadingLibraryScenariosList: true };
            if (has(scenarioMetricsPayloads, payload)) return { ...store, isLoadingScenariosMetrics: true };
            if (has(scenarioChangeStatusPayloads, payload)) return { ...store, isLoadingChangeScenarioStatus: true };
            if (has(scenarioTemplateMetricsPayloads, payload)) return { ...store, isLoadingScenarioTemplateMetrics: true };
            // TEMPLATES
            if (has(templatePayloads, payload)) return { ...store, isLoadingTemplates: true };
            if (has(updatingTemplatePayloads, payload)) return { ...store, isLoadingUpdatingTemplate: true };
            if (has(getTemplatePayloads, payload)) return { ...store, isLoadingTemplate: true };
            if (has(emailMastersPayloads, payload)) return { ...store, isLoadingEmailMasters: true };
            // IMAGES
            if (has(imageFoldersPayloads, payload)) return { ...store, isLoadingImageFolders: true };
            if (has(imagesPayloads, payload)) return { ...store, isLoadingImages: true };
            //  RECOMMENDATION
            if (has(recommendationMetricsPayloads, payload)) return { ...store, isLoadingRecommendationMetrics: true};
            if (has(recommendationPayloads, payload)) return { ...store, isLoadingRecommendation: true};
            // SEGMENTS
            if (has(segmentsListPayloads, payload)) return { ...store, isLoadingSegmentsList: true };
            if (has(segmentsListSilentUpdatePayloads, payload)) return { ...store, isLoadingSegmentsListSilentUpdate: true };
            if (has(segmentPayloads, payload)) return { ...store, isLoadingSegment: true };
            if (has(segmentClientEventPayloads, payload)) return { ...store, isLoadingClientEventSegment: true };
            if (has(segmentClientConsentPayloads, payload)) return { ...store, isLoadingClientConsents: true };
            // ANALYTICS
            if (has(analyticsPayloads, payload)) return { ...store, isLoadingAnalytics: true };
            //INTEGRATIONS
            if (has(integrationsPayloads, payload)) return { ...store, isLoadingIntegration: true };
            if (has(integrationsEventPayloads, payload)) return { ...store, isLoadingIntegrationEvent: true };
            //POLICIES
            if (has(frequencyPoliciesPayloads, payload)) return { ...store, isLoadingFrequencyPolicies: true };
            //AUTH
            if (has(authPayloads, payload)) return { ...store, isLoadingAuth: true };
            break;
        case apiEndType(payload):
            // COMMUNICATIONS
            if (has(communicationsListPayloads, payload)) return { ...store, isLoadingCommunicationsList: false };
            // CONSENT CATEGORIES
            if (has(consentCategoriesListPayloads, payload)) return { ...store, isLoadingConsentCategoriesList: false };
            // SCENARIOS
            if (has(scenarioListPayloads, payload)) return { ...store, isLoadingScenariosList: false };
            if (has(scenarioLibraryListPayloads, payload)) return { ...store, isLoadingLibraryScenariosList: false };
            if (has(scenarioMetricsPayloads, payload)) return { ...store, isLoadingScenariosMetrics: false };
            if (has(scenarioChangeStatusPayloads, payload)) return { ...store, isLoadingChangeScenarioStatus: false };
            if (has(scenarioTemplateMetricsPayloads, payload)) return { ...store, isLoadingScenarioTemplateMetrics: false };
            // TEMPLATES
            if (has(templatePayloads, payload)) return { ...store, isLoadingTemplates: false };
            if (has(updatingTemplatePayloads, payload)) return { ...store, isLoadingUpdatingTemplate: false };
            if (has(getTemplatePayloads, payload)) return { ...store, isLoadingTemplate: false };
            if (has(emailMastersPayloads, payload)) return { ...store, isLoadingEmailMasters: false };
            // IMAGES
            if (has(imageFoldersPayloads, payload)) return { ...store, isLoadingImageFolders: false };
            if (has(imagesPayloads, payload)) return { ...store, isLoadingImages: false };
            //  RECOMMENDATION
            if (has(recommendationMetricsPayloads, payload)) return { ...store, isLoadingRecommendationMetrics: false };
            if (has(recommendationPayloads, payload)) return { ...store, isLoadingRecommendation: false };
            // SEGMENTS
            if (has(segmentsListPayloads, payload)) return { ...store, isLoadingSegmentsList: false };
            if (has(segmentsListSilentUpdatePayloads, payload)) return { ...store, isLoadingSegmentsListSilentUpdate: false };
            if (has(segmentPayloads, payload)) return { ...store, isLoadingSegment: false };
            if (has(segmentClientEventPayloads, payload)) return { ...store, isLoadingClientEventSegment: false };
            if (has(segmentClientConsentPayloads, payload)) return { ...store, isLoadingClientConsents: false };
            // ANALYTICS
            if (has(analyticsPayloads, payload)) return { ...store, isLoadingAnalytics: false };
            //INTEGRATIONS
            if (has(integrationsPayloads, payload)) return { ...store, isLoadingIntegration: false };
            if (has(integrationsEventPayloads, payload)) return { ...store, isLoadingIntegrationEvent: false };
            //POLICIES
            if (has(frequencyPoliciesPayloads, payload)) return { ...store, isLoadingFrequencyPolicies: false };
            //AUTH
            if (has(authPayloads, payload)) return { ...store, isLoadingAuth: false };
            break;
        case API_REQUEST_FAIL_WITH_STATUS:
            return { ...store, catchedApiFailWithStatusCode: payload }
        case CLEAR_API_REQUEST_FAIL_WITH_STATUS:
            return { ...store, catchedApiFailWithStatusCode: null };
        default:
            return store;
    }
}
