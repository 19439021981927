import React from 'react';
import styles from './NamedCheckbox.module.css';
import { Checkbox } from 'shopify/components/Checkbox/Checkbox';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const NamedCheckbox = ({ text, isChecked, onChange, className }) => {
    return (
        <div className={cx(styles.container, className)}>
            <Checkbox
                onChange={onChange}
                isChecked={isChecked}
            />
            <TextPrimary
                text={text}
            />
        </div>
    )
};

NamedCheckbox.propTypes = {
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    className: PropTypes.string
};
NamedCheckbox.defaultProps = {
    className: ''
}