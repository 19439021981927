import React, { useEffect, useState } from 'react';
import styles from './ProgressBar.module.css';
import PropTypes from 'prop-types';
export const ProgressBar = ({ value }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(value);
    }, [value]);

    return (
        <div className={styles.container}>
            <div className={styles.progress} style={{ width: `${progress}%` }}>
                {value > 10
                    ? <span className={styles.value}>{progress}%</span>
                    : null
                }
            </div>
        </div>
    )
};
ProgressBar.propTypes = {
    value: PropTypes.number.isRequired
}